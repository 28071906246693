import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '@models/user.model';
import { RefundsListRequest } from '@models/refundsListRequest.model';
import { Refund } from '@models/refund.model';

@Injectable({
  providedIn: 'root',
})
export class RefundService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getRefunds(refundsListRequest: RefundsListRequest) {
    refundsListRequest.documentNumber = this.identity.userInfo.document;
    refundsListRequest.documentType = this.identity.userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: RefundsListRequest.toRequest(refundsListRequest),
      headers,
    };
    return this.http.get(`${this.urlApi}/pr_reintegros_v3`, options).pipe(
      map((resp: any) => {
        let refundsList: Array<Refund> = resp['REINTEGROS']
          ? resp['REINTEGROS'].map((refund) => Refund.fromResponse(refund))
          : [];
        return refundsList;
      })
    );
  }

  exportRefundsResult(refundsListRequest: RefundsListRequest) {
    refundsListRequest.documentNumber = this.identity.userInfo.document;
    refundsListRequest.documentType = this.identity.userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: RefundsListRequest.toRequest(refundsListRequest),
      headers,
    };
    return this.http.get(`${this.urlApi}/pr_exportar_reintegros_v3`, {
      headers: options.headers,
      params: options.params,
      responseType: 'blob',
    });
  }
}
