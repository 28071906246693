import { Component, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-request-settled',
  templateUrl: './modal-request-settled.component.html',
  styleUrls: ['./modal-request-settled.component.scss'],
})
export class ModalRequestSettledComponent implements OnDestroy {
  @Output() finish: EventEmitter<object> = new EventEmitter<object>();

  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.finish.emit();
  }
}
