export class SellersRequest {
  idContry: string;
  sellerUser: string;
  userDocumentType: string;
  userDocumentNumber: string;
  documentType: string;
  documentNumber: string;
  page: number;
  registers: number;

  public static toRequest(sellersRequest: SellersRequest): any {
    return {
      id_pais: sellersRequest.idContry,
      vendedor_usuario: sellersRequest.sellerUser,
      usuario_tipo_documento: sellersRequest.userDocumentType,
      usuario_documento: sellersRequest.userDocumentNumber,
      tipo_documento: sellersRequest.documentType,
      documento: sellersRequest.documentNumber,
      pagina: sellersRequest.page,
      registros: sellersRequest.registers
    };
  }
}
