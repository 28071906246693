export class MassiveRecordResponse {
  authorization: string;
  queryCode: string;
  documentNumber: string;
  date: string;
  chqNumber: string;
  originalChqNumber: string;
  observation: string;
  radication: string;
  documentType: string;
  value: string;

  public static fromResponse(json: any): MassiveRecordResponse {
    const massiveRecordResponse: MassiveRecordResponse = {
      authorization: json['AUTORIZACION'] ? json['AUTORIZACION'] : null,
      queryCode: json['CODIGO_CONSULTA'] ? json['CODIGO_CONSULTA'] : null,
      documentNumber: json['DOCUMENTO'] ? json['DOCUMENTO'] : null,
      date: json['FECHA'] ? json['FECHA'] : null,
      chqNumber: json['NUMERO_CHQ'] ? json['NUMERO_CHQ'] : null,
      originalChqNumber: json['NUMERO_CHQ_ORIGINAL']
        ? json['NUMERO_CHQ_ORIGINAL']
        : null,
      observation: json['OBSERVACION'] ? json['OBSERVACION'] : null,
      radication: json['RADICACION'] ? json['RADICACION'] : null,
      documentType: json['TIPO_DOCUMENTO'] ? json['TIPO_DOCUMENTO'] : null,
      value: json['VALOR'] ? json['VALOR'] : null,
    };
    return massiveRecordResponse;
  }
}
