import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SubjectService } from '@services/subject.service';
import { UsersResponse } from '@models/usersResponse.model';

@Component({
  selector: 'app-card-user',
  templateUrl: './card-user.component.html',
  styleUrls: ['./card-user.component.scss'],
})
export class CardUserComponent implements OnInit {
  @Input() dataCardsUsers: [];
  @Input() showEdit: boolean;
  @Output() edit: EventEmitter<UsersResponse> =
    new EventEmitter<UsersResponse>();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() enable: EventEmitter<any> = new EventEmitter();
  @Output() disable: EventEmitter<UsersResponse> =
    new EventEmitter<UsersResponse>();

  public message = 'El usuario debe cambiar la contraseña.';

  constructor(public subject: SubjectService) {}

  ngOnInit() {}

  editUser(user: UsersResponse) {
    this.edit.emit(user);
  }

  deleteUser(user: UsersResponse) {
    this.delete.emit(user);
  }

  enableUser(user: UsersResponse) {
    this.enable.emit(user);
  }

  disableUser(user: UsersResponse) {
    this.disable.emit(user);
  }

  // Tooltip icon change password
  enter() {
    this.subject.sendText(this.message);
    console.log(this.message);
  }

  leave() {
    this.subject.sendText('');
  }
}
