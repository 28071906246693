import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { TransactionRequest } from '@models/transactionRequest.model';
import { Reserve } from '@models/reserve.model';
import { TransactionDue } from '@models/transactionDue.model';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { TransactionService } from '@services/transaction.service';

import { CONSTANTS } from '@config/constants';

@Component({
  selector: 'app-tab-bill',
  templateUrl: './tab-bill.component.html',
  styleUrls: ['./tab-bill.component.scss'],
})
export class TabBillComponent implements OnInit {
  @Input() transactionUser: UserAvailableSpaceRequest;
  @Input() userAvailableSpace: UserAvailableSpaceResponse;
  @Input() reserveList: Array<Reserve>;

  public constants: any = CONSTANTS;

  public userAvailable: number = 0;
  public allowWithoutQuote: boolean = false;

  ngOnInit() {
    if (
      this.userAvailableSpace.buyerSpace &&
      this.userAvailableSpace.buyerSpace.status ==
        CONSTANTS.TRANSACTIONS.ALLOW_WITHOUT_QUOTE
    ) {
      this.allowWithoutQuote = true;
    } else if (
      this.userAvailableSpace.buyerSpace &&
      (this.userAvailableSpace.buyerSpace.alert ==
        CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT ||
        this.userAvailableSpace.buyerSpace.alert ==
          CONSTANTS.TRANSACTIONS.WITH_AGREEMENT)
    ) {
      this.userAvailable = parseFloat(
        this.userAvailableSpace.buyerSpace.spaceAvailableAgreement
      );
    } else if (
      this.userAvailableSpace.buyerSpace &&
      this.userAvailableSpace.buyerSpace.status !=
        CONSTANTS.TRANSACTIONS.ALLOW_WITHOUT_QUOTE &&
      this.userAvailableSpace.buyerSpace.alert !=
        CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT &&
      this.userAvailableSpace.buyerSpace.alert !=
        CONSTANTS.TRANSACTIONS.WITH_AGREEMENT
    ) {
      this.userAvailable = parseFloat(
        this.userAvailableSpace.buyerSpace.spaceAvailable
      );
    }
    this.transactionRequest.reservationCode =
      this.transactionUser.reservationCode || '';
  }

  // Properties
  public transactionRequest: TransactionRequest = {
    idContry: '1',
    sellerDocumentType: '',
    sellerDocumentNumber: '',
    documentType: '',
    documentNumber: '',
    reservationCode: '',
    queryCode: '',
    transactionType: 'F',
    titleValue: '',
    username: '',
    expirationDate: null,
    transactionValue: null,
    bank: '0',
    acount: '20201219',
    municipality: '0',
    phone: '0',
    celPhone: '0',
    addres: '.',
    transactionDues: [],
  };

  selectedReservationCodeValue() {
    return parseFloat(
      this.reserveList.find((reserve: Reserve) => {
        return (
          reserve.reservationCode == this.transactionRequest.reservationCode
        );
      }).totalValue
    );
  }

  public minDate = moment(new Date()).toDate();
  public maxDate = moment(new Date()).add(60, 'days').toDate();

  public approvalCode: string;
  public errorMessage: string;
  public individualTransaction = true;

  constructor(
    public helper: HelperService,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private transactionService: TransactionService
  ) {}

  transactionBill() {
    this.modal.show('transactionConfirm');
  }

  dateTextChange($event) {
    this.transactionRequest.expirationDate = moment(
      $event.target.value,
      'DD/MM/YYYY'
    ).toDate();
  }

  sendTransaction($event) {
    this.transactionRequest.sellerDocumentNumber = this.transactionUser.sellerDocumentNumber;
    this.transactionRequest.sellerDocumentType = this.transactionUser.sellerDocumentType;
    this.transactionRequest.documentNumber = this.transactionUser.documentNumber;
    this.transactionRequest.documentType = this.transactionUser.documentType;
    this.transactionRequest.queryCode = this.transactionUser.queryCode;
    let transactionDues: Array<TransactionDue> = [
      {
        date: this.transactionRequest.expirationDate,
        dateString: '',
        title: this.transactionRequest.titleValue,
        value: this.transactionRequest.transactionValue,
        check: false,
      },
    ];
    this.transactionRequest.transactionDues = transactionDues;
    this.spinner.show();
    this.transactionService
      .createTransaction(this.transactionRequest)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.error) {
            this.errorMessage = response.message;
            this.modal.show('transactionDenied');
          } else {
            this.approvalCode = response.approvalCode;
            this.modal.show('transactionSuccess');
          }
        },
        (err) => {
          this.spinner.hide();
          this.modal.show('transactionDenied');
        }
      );
  }
}
