import { HttpHeaders } from '@angular/common/http';
import { DocumentResponse } from '@models/documentResponse.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  public urlApi: string;
  public userToken: string;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
  }

  readToken() {
    if (localStorage.getItem("tokenCF")) {
      this.userToken = localStorage.getItem("tokenCF");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  getDocuments() {
    const headers = new HttpHeaders().set("token", this.userToken);
    const options = {
      headers
    };
    return this.http.get(`${this.urlApi}/listado_documentos`, options).pipe(
      map((resp: any) => {
         let documents: Array<DocumentResponse> = [];
         documents = resp['DOCUMENTOS'] && resp['DOCUMENTOS'].length > 0
                   ? resp['DOCUMENTOS'].map((document: DocumentResponse) => DocumentResponse.fromResponse(document))
                   : [];
         return documents;
      })
    );
    
  }
}
