import { SelectsService } from '@services/selects.service';
import { UserRoles } from '@models/userRolesResponse.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { UsersResponse } from '@models/usersResponse.model';
import { UsersService } from '@services/users.service';
import { UsersRequest } from '@models/usersRequest.model';
import { AddUserRequest } from '@models/addUserRequest.model';
import { Department } from '@models/department.model';
import { City } from '@models/city.model';
import { HelperService } from '@services/helper.service';
import { PaginationComponent } from '@components/layout/pagination/pagination.component';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
import { AuthService } from '@services/auth.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})

/**
 * Este es un container component
 */
export class UsersComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public totalPages: number = 1;
  public pageLimit: number = 5;
  public activeContent: string;
  public action: string;
  public userSelected: UsersResponse;
  public departments: Array<Department>;
  public roles: Array<UserRoles>;
  public cities: Array<City>;
  public addUserRequest: AddUserRequest = new AddUserRequest();

  public usersRequest: UsersRequest = {
    idContry: '1',
    documentType: '',
    documentNumber: '',
    page: 1,
    registers: 5,
    search: '0',
  };

  public usersResponse: UsersResponse = new UsersResponse();

  public permissions: Array<PermissionModel>;
  public constants = CONSTANTS;
  public addUserStatus = 'A';
  public editUserStatus = 'A';

  constructor(
    private usersService: UsersService,
    public modal: ModalService,
    public helper: HelperService,
    private selectsService: SelectsService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.users(1);
    this.selects();
    this.permissions = this.usersService.permissions;
    const addPermissionStatus = this.permissions.filter(
      (permission) => permission.idOption === '30'
    );
    if (addPermissionStatus.length > 0) {
      this.addUserStatus = addPermissionStatus[0].status;
    }
    const editPermissionStatus = this.permissions.filter(
      (permission) => permission.idOption === '31'
    );
    if (editPermissionStatus.length > 0) {
      this.editUserStatus = editPermissionStatus[0].status;
    }
  }

  pageSelected({ currentPage }) {
    this.users(currentPage);
  }

  onSearch(searchParam: string) {
    if (searchParam && searchParam.length < 1) {
      this.refresh();
      return;
    }
    this.usersRequest.search = searchParam || '0';
    this.users(1);
  }

  users(currentPage: number) {
    this.usersRequest.page = currentPage;
    this.usersService.getUsers(this.usersRequest).subscribe(
      (users: any) => {
        this.usersResponse = users;
        this.totalPages = this.helper.getAmountOfPages(users, this.pageLimit);
      },
      (err: any) => {
        console.error(err);
        this.usersResponse = new UsersResponse();
      }
    );
  }

  showAddUserForm() {
    this.activeContent = 'form-user';
    this.action = 'add';
  }

  async showEditUserForm(user: UsersResponse) {
    this.activeContent = 'form-user';
    this.action = 'edit';
    this.userSelected = user;
    this.addUserRequest.mustChangePass = this.userSelected.mustChangePass;
    await this.formatUserData(this.userSelected);
  }

  modalDisableUser(user: UsersResponse) {
    this.action = 'disable';
    this.modal.show('userDisable', {
      name: `${user.name} ${user.lastName}`,
      userId: user.user,
    });
    this.userSelected = user;
  }

  modalEnableUser(user: UsersResponse) {
    this.action = 'enable';
    this.modal.show('userEnable', {
      name: `${user.name} ${user.lastName}`,
      userId: user.user,
    });
    this.userSelected = user;
  }

  modalDeleteUser(user: UsersResponse) {
    this.action = 'delete';
    this.modal.show('userDelete', {
      name: `${user.name} ${user.lastName}`,
      userId: user.user,
    });
    this.userSelected = user;
  }

  async disableUser() {
    const state = 'I';
    const type = 'ACTUALIZAR';
    await this.formatUserData(this.userSelected);
    this.adminUser(state, type);
  }

  async enableUser() {
    const state = 'A';
    const type = 'ACTUALIZAR';
    await this.formatUserData(this.userSelected);
    this.adminUser(state, type);
  }

  async deleteUser() {
    const state = 'E';
    const type = 'RETIRAR';
    await this.formatUserData(this.userSelected);
    this.adminUser(state, type);
  }

  submitAction(userFromAddForm: AddUserRequest) {
    if (this.action === 'add') {
      this.addUser(userFromAddForm);
    } else {
      this.editUser(userFromAddForm);
    }
  }

  async addUser(userFromAddForm: AddUserRequest) {
    const state = 'A';
    const type = 'CREAR';
    this.addUserRequest = userFromAddForm;
    this.adminUser(state, type);
  }

  async editUser(userFromAddForm: AddUserRequest) {
    const state = 'A';
    const type = 'ACTUALIZAR';
    this.addUserRequest = userFromAddForm;
    this.adminUser(state, type);
  }

  back() {
    this.activeContent = '';
    this.refresh();
  }

  async formatUserData(userSelected: UsersResponse) {
    const {
      userDocumentType,
      userDocumentNumber,
      name,
      lastName,
      email,
      cellphone,
      cityOffice,
      city,
      department,
      rol,
      birthday,
    } = userSelected;
    this.addUserRequest.sellerDocumentType = userDocumentType;
    this.addUserRequest.sellerDocumentNumber = userDocumentNumber;
    this.addUserRequest.birthday = birthday;
    const names = name.split(' ');
    this.addUserRequest.name1 = names[0] || '';
    this.addUserRequest.name2 = names[1] || '';
    const lastNames = lastName.split(' ');
    this.addUserRequest.lastName1 = lastNames[0] || '';
    this.addUserRequest.lastname2 = lastNames[1] || '';
    this.addUserRequest.email = email;
    this.addUserRequest.cellphone = cellphone;
    this.addUserRequest.cityOffice = cityOffice;
    this.addUserRequest.idRol = this.getRolIdByName(rol);

    this.addUserRequest.department = this.getDepartmentIdByName(department);
    await this.getCities(this.addUserRequest.department);
    const cityId = this.getCityIdByName(city);
    this.addUserRequest.municipalList = cityId || '';
  }

  selects() {
    this.departments = this.helper.getDepartments();
    this.userRoles();
  }

  userRoles() {
    this.selectsService.getUserRoles().subscribe(
      (roles: Array<UserRoles>) => {
        this.roles = roles;
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  getDepartmentIdByName(departmentName: string) {
    const idDepartment = this.departments
      .filter((departmentItem: any) => departmentItem.value == departmentName)
      .map((departmentById: any) => departmentById.id);
    return idDepartment[0];
  }

  getCityIdByName(cityName: string) {
    console.log('this.cities', this.cities);
    console.log('cityName', cityName);
    const idCity = this.cities
      .filter((cityItem: any) => cityItem.value == cityName)
      .map((cityById: any) => cityById.id);
    return idCity[0];
  }

  getRolIdByName(rolName: string) {
    rolName = rolName === 'SUPER ADMINISTRADOR' ? 'ADMINISTRADOR' : rolName;
    const idRol = this.roles
      .filter((rolItem: any) => rolItem.value == rolName)
      .map((rolById: any) => rolById.id);
    return idRol[0];
  }

  getCities(department: string) {
    return new Promise((resolve, reject) => {
      this.helper.getCities(department).subscribe(
        (cities: Array<City>) => {
          this.cities = cities;
          resolve(cities);
        },
        (err: any) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  getFormattedDocumentType = (documentType) => {
    let formattedDocumentType = documentType;
    if (documentType === 'C') {
      formattedDocumentType = 'CC';
    }
    if (documentType === 'E') {
      formattedDocumentType = 'CE';
    }
    return formattedDocumentType;
  };

  adminUser(state: string, type: string) {
    this.addUserRequest.adminAction = type;
    this.addUserRequest.state = state;
    delete this.addUserRequest.mustChangePass;
    this.usersService.adminUser(this.addUserRequest).subscribe(
      (resp: any) => {
        if (
          resp &&
          resp['MENSAJE'] !== 'Error en la creacion del usuario' &&
          resp['MENSAJE'] !== 'El usuario ya existe'
        ) {
          if (this.action === 'add') {
            const userFormatted =
              this.getFormattedDocumentType(
                this.addUserRequest.sellerDocumentType
              ) + this.addUserRequest.sellerDocumentNumber;

            this.auth
              .recoverPassword(userFormatted, CONSTANTS.USERS.CREATE)
              .subscribe(
                (resp: any) => {
                  console.log('resp', resp);
                },
                (err) => {}
              );
          }
          const modalType = this.getModalType(this.action);
          this.modal.show(`${modalType}`, {
            userId: `${this.addUserRequest.sellerDocumentType}${this.addUserRequest.sellerDocumentNumber}`,
            email: this.addUserRequest.email,
          });

          this.users(1);
          this.selects();
        } else {
          this.modal.show('error', {
            title: `No se pudo ${
              state === 'E'
                ? 'Eliminar'
                : this.action === 'add'
                ? 'Crear'
                : 'Actualizar'
            } el usuario: ${this.addUserRequest.sellerDocumentType}${
              this.addUserRequest.sellerDocumentNumber
            }`,
            message:
              resp['MENSAJE'] === 'El usuario ya existe'
                ? 'El usuario ya existe'
                : 'Por favor intente más tarde',
          });
        }
      },
      (err: any) => {
        console.error(err);
        this.modal.show('error', {
          title: `No se pudo ${
            state === 'E'
              ? 'Eliminar'
              : this.action === 'add'
              ? 'Crear'
              : 'Actualizar'
          } el usuario: ${this.addUserRequest.sellerDocumentType}${
            this.addUserRequest.sellerDocumentNumber
          }`,
          message: 'Por favor intente más tarde',
        });
      },
      () => {
        const actionsToValidate = ['add', 'edit'];
        if (!actionsToValidate.includes(this.action))
          this.paginationComponent.reset();
      }
    );
  }

  getModalType(type: string) {
    switch (type) {
      case 'delete':
        return 'userDeleteSuccess';
      case 'disable':
        return 'userDisableSuccess';
      case 'enable':
        return 'userEnableSuccess';
      case 'add':
        return 'userAddSuccess';
      case 'edit':
        return 'userUpdateSuccess';
      default:
        return '';
    }
  }

  refresh() {
    this.helper.refresh('/pages/administracion/usuarios');
  }
}
