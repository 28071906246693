import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { TransactionsComponent } from '@pages/transactions/transactions.component';
import { IndividualTransactionComponent } from '@pages/transactions/individual-transaction/individual-transaction.component';
import { ClientStudyComponent } from '@pages/client-study/client-study.component';
import { OperationsComponent } from '@pages/operations/operations.component';
import { ClaimsComponent } from '@pages/claims/claims.component';
import { AgreementsComponent } from '@pages/agreements/agreements.component';
import { ReportsComponent } from '@pages/reports/reports.component';
import { AdministrationComponent } from '@pages/administration/administration.component';
import { TabsComponent } from '@pages/transactions/tabs/tabs.component';
import { MyPaymentsComponent } from '@pages/administration/my-payments/my-payments.component';
import { UsersComponent } from '@pages/administration/users/users.component';
import { DocumentsComponent } from '@pages/administration/documents/documents.component';
import { ProfileComponent } from '@pages/administration/profile/profile.component';
import { OperationsTabsComponent } from '@pages/operations/operations-tabs/operations-tabs.component';
import { AuthGuard } from '@guards/auth.guard';
import { RolGuard } from '@app/guards/rol.guard';
import { TabCheckComponent } from './transactions/tab-check/tab-check.component';

const routes: Routes = [
  {
    path: 'pages',
    component: PagesComponent,
    canActivate: [AuthGuard, RolGuard],
    children: [
      {
        path: 'transacciones',
        component: TransactionsComponent,
        children: [
          { path: '', component: TabsComponent, canActivate: [AuthGuard] },
          {
            path:
              'transaccion-individual/:documentType/:documentNumber/:queryCode',
            component: IndividualTransactionComponent,
            canActivate: [AuthGuard],
          },
          {
            path:
              'transaccion-individual/:documentType/:documentNumber/:queryCode/:reservationCode',
            component: IndividualTransactionComponent,
            canActivate: [AuthGuard],
          },
          // {
          //   path: 'test', component: TabCheckComponent
          // }
        ],
      },
      {
        path: 'estudio-de-cliente',
        component: ClientStudyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'operaciones',
        component: OperationsComponent,
        children: [
          {
            path: '',
            component: OperationsTabsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'reclamaciones',
        component: ClaimsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reintegros',
        component: ClaimsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'acuerdos-de-pago',
        component: AgreementsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reportes',
        component: ReportsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'administracion',
        component: AdministrationComponent,
        children: [
          {
            path: 'mis-pagos',
            component: MyPaymentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'usuarios',
            component: UsersComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'documentos',
            component: DocumentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'perfil',
            component: ProfileComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      { path: '**', pathMatch: 'full', redirectTo: '/not-found' },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
