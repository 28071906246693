import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-upload-claim-documents',
  templateUrl: './upload-claim-documents.component.html',
  styleUrls: ['./upload-claim-documents.component.scss']
})
export class UploadClaimDocumentsComponent implements OnInit {
  // Radio
  private radioSelected = 'bill';
  public radio = [
    { name: 'Factura física', value: 'bill' },
    { name: 'Factura electrónica', value: 'electronic_bill' },
  ];

  public dataBill = [
    { id: 1, title: 'Factura original', progress: 0, required: true },
    { id: 2, title: 'Endoso de la factura', progress: 0, required: true },
    { id: 3, title: 'Aceptación tácita', progress: 0, required: false },
    { id: 4, title: 'Notificación del comprador', progress: 0, required: false },
  ];

  public dataElectronicBill = [
    { id: 1, title: 'Factura original', progress: 0, required: true },
    { id: 2, title: 'Endoso de la factura', progress: 0, required: true },
    { id: 3, title: 'Archivo.xml', progress: 0, required: false },
    { id: 3, title: 'Certificación de proveedor', progress: 0, required: false },
    { id: 3, title: 'Aceptación tácita', progress: 0, required: false },
    { id: 4, title: 'Notificación del comprador', progress: 0, required: false },
  ];

  @Output() back: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(public modal: ModalService) { }

  ngOnInit() {
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(Item => Item.value === this.radioSelected);
    return radioSel.value;
  }

  handleBack() {
    this.back.emit();
  }

  handleContinue() {
   this.modal.show('claimConfirm');
  }

}
