export class MassiveRecord {
  queryCode: string;
  documentNumber: string;
  date: string;
  documentType: string;
  valueTitle: string;
  message: MassiveRecord;
  value: string;

  public static fromResponse(json: any): MassiveRecord {
    const massiveRequets: MassiveRecord = {
      queryCode: json['CODIGO_CONSULTA'] ? json['CODIGO_CONSULTA'] : null,
      documentNumber: json['DOCUMENTO'] ? json['DOCUMENTO'] : null,
      date: json['FECHA'] ? json['FECHA'] : null,
      documentType: json['TIPO_DOCUMENTO'] ? json['TIPO_DOCUMENTO'] : null,
      message: json['MENSAJE']
        ? MassiveRecord.fromResponse(json['MENSAJE'])
        : null,
      valueTitle: json['TITULO_VALOR'] ? json['TITULO_VALOR'] : null,
      value: json['VALOR'] ? json['VALOR'] : null,
    };
    return massiveRequets;
  }

  public static toRequest(
    massiveRecord: MassiveRecord,
    isExoneration: boolean = false
  ): any {
    const massiveRecordJson = {
      DOCUMENTO: massiveRecord.documentNumber,
      FECHA: massiveRecord.date,
      TIPO_DOCUMENTO: massiveRecord.documentType,
      TITULO_VALOR: massiveRecord.valueTitle,
      // MENSAJE: massiveRecord.message,
      VALOR: massiveRecord.value,
    };
    if (!isExoneration) {
      massiveRecordJson['CODIGO_CONSULTA'] = massiveRecord.queryCode;
    }
    return massiveRecordJson;
  }

  public static toSend(
    massiveRecord: MassiveRecord,
    isExoneration: boolean = false
  ): any {
    const massiveRecordJson = {
      DOCUMENTO: massiveRecord.documentNumber,
      FECHA: massiveRecord.date,
      TIPO_DOCUMENTO: massiveRecord.documentType,
      TITULO_VALOR: massiveRecord.valueTitle,
      VALOR: massiveRecord.value,
    };
    if (!isExoneration) {
      massiveRecordJson['CODIGO_CONSULTA'] = massiveRecord.queryCode;
    }
    return massiveRecordJson;
  }
}
