import { HelperService } from "@services/helper.service";
import { Component, Output, Input, EventEmitter } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent {
  // Properties
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  @Output() keyPress: EventEmitter<string> = new EventEmitter();
  @Input() text: boolean = true;
  public searchString: string;

  constructor(
    public spinner: NgxSpinnerService,
    public helper: HelperService
  ) {}

  search() {
    this.onSearch.emit(this.searchString);
  }

  onKeyPressSearch() {
    this.keyPress.emit(this.searchString);
  }
}
