export class AddUserRequest {
  idContry: string = '1';
  user: string = '';
  documentType: string = '';
  documentNumber: string = '';
  email: string = '';
  name1: string = '';
  name2?: string = '';
  lastName1: string = '';
  lastname2: string = '';
  birthday?: Date;
  adminAction: string = '';
  newPass?: string = '';
  confirmNewPass?: string = '';
  idRol: string = '';
  sellerDocumentType: string = '';
  sellerDocumentNumber: string = '';
  cellphone: number = 0;
  department?: string = '';
  municipalList: string = '';
  cityOffice: string = '';
  state: string = '';
  mustChangePass?: string = '';

  public static toRequest(addUserRequest: AddUserRequest): any {
    return {
      id_pais: addUserRequest.idContry,
      usuario: addUserRequest.user,
      tipo_documento: addUserRequest.documentType,
      documento: addUserRequest.documentNumber,
      correo: addUserRequest.email,
      nombre_1: addUserRequest.name1,
      nombre_2: addUserRequest.name2,
      apellido_1: addUserRequest.lastName1,
      apellido_2: addUserRequest.lastname2,
      administrar: addUserRequest.adminAction,
      nuevacontrasena: addUserRequest.newPass,
      idrol: addUserRequest.idRol,
      vendedor_tipo_documento: addUserRequest.sellerDocumentType,
      vendedor_documento: addUserRequest.sellerDocumentNumber,
      celular: addUserRequest.cellphone,
      lista_municipio: addUserRequest.municipalList,
      sucursal: addUserRequest.cityOffice,
      estado: addUserRequest.state,
    };
  }
}
