import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export class BirthdayRequest {
  idContry: string = '1';
  user: string;
  documentType: string = '';
  documentNumber: string = '';
  userDate: Date;

  public static toRequest(birthdayRequest: BirthdayRequest): any {
    return {
      id_pais: birthdayRequest.idContry,
      usuario: birthdayRequest.user,
      tipo_documento: birthdayRequest.documentType,
      documento: birthdayRequest.documentNumber,
      fecha_usuario: moment(birthdayRequest.userDate).format('DDMMYYYY'),
    };
  }
}
