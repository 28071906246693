import * as moment from 'moment';

export class IndividualOperationRequest {
  documentType?: string;
  documentNumber?: string;
  page: Number;
  search: string;
  startDate: Date;
  endDate: Date;

  public static toRequest(
    individualOperationRequest: IndividualOperationRequest
  ): any {
    return {
      tipo_documento: individualOperationRequest.documentType,
      documento: individualOperationRequest.documentNumber,
      id_pais: 1,
      pagina: individualOperationRequest.page,
      registros: 5,
      ordenar: 'DOCUMENTO',
      ordenamiento: 'desc',
      busqueda: individualOperationRequest.search
        ? individualOperationRequest.search
        : 0,
      fecha_inicial: moment(individualOperationRequest.startDate).format(
        'YYYYMMDD'
      ),
      fecha_final: moment(individualOperationRequest.endDate).format(
        'YYYYMMDD'
      ),
    };
  }
}
