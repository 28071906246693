import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReserveListRequest } from '@models/reserveListRequets.model';
import { UserModel } from '@models/user.model';
import { Reserve } from '@models/reserve.model';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';

@Injectable({
  providedIn: 'root',
})
export class ReserveService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getReserves(page: string, limit: string, sellerDocumentNumber: string) {
    let reserveListRequest: ReserveListRequest = {
      searchTerm: sellerDocumentNumber,
      documentNumber: this.identity.userInfo.document,
      documentType: this.identity.userInfo.documentType,
      page,
      records: limit,
      idContry: '1',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ReserveListRequest.toRequest(reserveListRequest),
      headers,
    };
    return this.http.get(`${this.urlApi}/pr_listado_reservas`, options).pipe(
      map((resp: any) => {
        let reserveList: Array<Reserve> = resp['LISTADO_RESERVA']
          ? resp['LISTADO_RESERVA'].map((reserve) =>
              Reserve.fromResponse(reserve)
            )
          : [];
        return reserveList;
      })
    );
  }

  getLegacyReserves(
    page: string,
    limit: string,
    sellerDocumentNumber: string,
    sellerDocumentType: string
  ) {
    let reserveListRequest: ReserveListRequest = {
      searchTerm: sellerDocumentNumber,
      documentNumber: this.identity.userInfo.document,
      documentType: this.identity.userInfo.documentType,
      sellerDocumentNumber,
      sellerDocumentType,
      page,
      records: limit,
      idContry: '1',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ReserveListRequest.toLegacyRequest(reserveListRequest),
      headers,
    };
    return this.http.get(`${this.urlApi}/pr_reserva_detalle`, options).pipe(
      map((resp: any) => {
        let reserveList: Array<Reserve> = resp['RESERVA_DETALLE']
          ? resp['RESERVA_DETALLE'].map((reserve) =>
              Reserve.fromResponse(reserve)
            )
          : [];
        return reserveList;
      })
    );
  }

  createReserve(
    transactionUser: UserAvailableSpaceRequest,
    reservationValue: number
  ) {
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: UserAvailableSpaceRequest.toReserveRequest(
        transactionUser,
        reservationValue,
        this.identity.username
      ),
      headers,
    };
    return this.http
      .post(`${this.urlApi}/pr_solicitud_reservas`, {}, options)
      .pipe(
        map((resp: any) => {
          return resp['Clave: {}'];
        })
      );
  }
}
