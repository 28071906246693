import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PermissionModel } from '../models/permission.model';
import { LoginModel, RecoverPasswordModel } from '@models/login.model';
import { UserModel } from '@models/user.model';
import { CONSTANTS } from '@app/config/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // properties
  private urlApi: string;
  public headers: HttpHeaders;
  public identity: UserModel;
  public userToken: any;
  public permissions: Array<PermissionModel>;
  public quotaStudy: boolean;
  public quotaReport: boolean;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.setPermisions();
  }

  login(user: LoginModel) {
    this.isAutenticated();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: LoginModel.toResponse(user), headers };

    return this.http.get(`${this.urlApi}/acceso`, options).pipe(
      map((resp: any) => {
        // Expires
        const expiraToken = new Date(resp.expira);
        localStorage.setItem('expiresCF', expiraToken.getTime().toString());
        localStorage.setItem(
          'user',
          JSON.stringify({
            userEmail: user.username,
            token: resp['TOKEN'],
            userInfo: resp,
          })
        );
        this.saveToken(resp['TOKEN']);
        return resp;
      })
    );
  }

  recoverPassword(username: string, option: string = CONSTANTS.USERS.RECOVER) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const recoverPassword: RecoverPasswordModel = {
      username,
    };
    const options = {
      params: RecoverPasswordModel.toResponse(recoverPassword, option),
      headers,
    };

    return this.http.get(`${this.urlApi}/envio_olvide_contrasena`, options);
  }

  getFamilyList() {
    return this.http.get(`${this.urlApi}/listado_parentesco`).pipe(
      map((resp: any) => {
        localStorage.setItem('familyList', JSON.stringify(resp.PARENTESCO));
        return resp;
      })
    );
  }

  getCareersList() {
    return this.http.get(`${this.urlApi}/listado_carreras`).pipe(
      map((resp: any) => {
        localStorage.setItem('careerList', JSON.stringify(resp.CARRERAS));
        return resp;
      })
    );
  }

  getPermissions() {
    this.getIdentity();
    const roleParams: any = {
      id_pais: 1,
      tipo_documento: this.identity.userInfo.documentType,
      documento: this.identity.userInfo.document,
      idrol: this.identity.userInfo.useRoler,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = { params: roleParams, headers };
    return this.http.get(`${this.urlApi}/pr_rol_opciones_v2`, options).pipe(
      map((resp: any) => {
        localStorage.setItem('permissions', JSON.stringify(resp));
        this.permissions = resp.MENU.map((permission) =>
          PermissionModel.fromResponse(permission)
        );
        this.quotaStudy = resp.ESTUDIO_CUPO && resp.ESTUDIO_CUPO === 'SI';
        this.quotaReport = resp.REPORTE_CUPOS && resp.REPORTE_CUPOS === 'SI';
        return resp;
      })
    );
  }

  setPermisions() {
    const permissions = localStorage.getItem('permissions');
    if (permissions) {
      const parsedPermissions = JSON.parse(permissions);
      this.permissions = parsedPermissions.MENU.map((permission) =>
        PermissionModel.fromResponse(permission)
      );
      this.quotaStudy =
        parsedPermissions.ESTUDIO_CUPO &&
        parsedPermissions.ESTUDIO_CUPO === 'SI';
      this.quotaReport =
        parsedPermissions.REPORTE_CUPOS &&
        parsedPermissions.REPORTE_CUPOS === 'SI';
    }
  }

  getGlobalData() {
    return this.http.get(`${this.urlApi}/inicializar`).pipe(
      map((resp: any) => {
        localStorage.setItem('documents', JSON.stringify(resp));
        return resp;
      })
    );
  }

  private saveToken(token: string) {
    this.userToken = token;
    localStorage.setItem('tokenCF', token);
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  logout() {
    localStorage.removeItem('tokenCF');
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  isAutenticated(): boolean {
    const today = new Date().getTime();
    const expira = Number(localStorage.getItem('expiresCF'));
    if (
      localStorage.getItem('tokenCF') === undefined ||
      localStorage.getItem('tokenCF') === null ||
      expira < today
    ) {
      return false;
    } else {
      return this.userToken;
    }
  }

  // Examples:
  post(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }

  put(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }
}
