export class UserRoles {
    id: string;
    value: string;

    public static fromResponse(json: any): UserRoles {
        const userRoles: UserRoles = {
            id: json['IDROL'],
            value: json['DESCROL'],
        }
        return userRoles;
    }

}