import { Component, OnInit } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { PaymentAgreementsService } from '@services/payment-agreements.service';
import { PaymentAgreement } from '@models/paymentAgreement.model';
@Component({
  selector: 'app-tab-active-agreements',
  templateUrl: './tab-active-agreements.component.html',
  styleUrls: ['./tab-active-agreements.component.scss'],
})
export class TabActiveAgreementsComponent implements OnInit {
  public paymentAgreementList: Array<PaymentAgreement> = [];

  public search: string = '';
  public totalPages: number = 1;
  public pageLimit: number = 10;
  public page: number = 1;
  public error: boolean = false;
  public minValue: number = 0;
  public maxValue: number = 0;
  public selectedPaymentAgreement: number;

  constructor(
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    public modal: ModalService,
    public paymentAgreementsService: PaymentAgreementsService
  ) {}

  ngOnInit() {
    this.loadPaymentAgreement();
  }

  loadPaymentAgreement() {
    this.spinner.show();
    this.paymentAgreementsService
      .getPaymentAgreements(
        this.page.toString(),
        this.pageLimit.toString(),
        this.search
      )
      .subscribe(
        (paymentAgreementList: Array<PaymentAgreement>) => {
          this.paymentAgreementList = paymentAgreementList;
          this.totalPages =
            paymentAgreementList.length > 0
              ? Math.ceil(
                  parseInt(paymentAgreementList[0].numberOfRecords) /
                    this.pageLimit
                )
              : 1;
          this.spinner.hide();
        },
        (err) => {
          console.error('err', err);
          this.spinner.hide();
        }
      );
  }

  pageSelected(page: any) {
    this.page = page.currentPage;
    this.loadPaymentAgreement();
  }

  onSearch(searchText: string) {
    this.search = searchText ? searchText : '0';
    this.loadPaymentAgreement();
  }

  // noPay() {
  //   this.modal.show('nopayConfirm');
  // }

  canSend() {
    let selectedPaymentAgreement;
    this.paymentAgreementList.forEach((paymentAgreement, index) => {
      if (index === this.selectedPaymentAgreement) {
        selectedPaymentAgreement = paymentAgreement;
      }
    });
    if (selectedPaymentAgreement) {
      const valueToPay = selectedPaymentAgreement.valueToPay;
      const valueToExonerate = parseFloat(
        selectedPaymentAgreement.valueToExonerate
      );
      let minValue = valueToExonerate * 0.5;
      if (valueToExonerate < 10000000) {
        minValue = valueToExonerate * 0.5;
      } else if (valueToExonerate < 20000000) {
        minValue = valueToExonerate * 0.5;
      } else if (valueToExonerate < 50000000) {
        minValue = valueToExonerate * 0.4;
      } else if (valueToExonerate <= 10000000) {
        minValue = valueToExonerate * 0.2;
      } else if (valueToExonerate > 10000000) {
        minValue = valueToExonerate * 0.1;
      }
      if (
        (parseInt(selectedPaymentAgreement.agreement) === 0 &&
          valueToPay < minValue) ||
        valueToPay > valueToExonerate
      ) {
        this.error = true;
        this.minValue = minValue;
        this.maxValue = valueToExonerate;
        return false;
      } else {
        this.error = false;
        return true;
      }
    }
    this.error = false;
    return false;
  }

  errorMessage(minValue, maxValue) {
    return `El valor debe ser mayor a ${minValue} y menor a ${maxValue}`;
  }

  pay() {
    let selectedPaymentAgreement: PaymentAgreement;
    this.paymentAgreementList.forEach((paymentAgreement, index) => {
      if (index === this.selectedPaymentAgreement) {
        selectedPaymentAgreement = paymentAgreement;
      }
    });
    this.modal.show('paymentAgreementConfirm', {
      title: 'Confirmar abono',
      name: selectedPaymentAgreement.name,
      buyerTitle: selectedPaymentAgreement.guaranteedTitles,
      agreementTitle: selectedPaymentAgreement.titlesToExonerate,
      agreementTotal: selectedPaymentAgreement.valueToPay,
      cantTitle:
        parseFloat(selectedPaymentAgreement.guaranteedTitles) -
        parseFloat(selectedPaymentAgreement.titlesToExonerate),
    });
  }

  sendPayment() {
    let selectedPaymentAgreement: PaymentAgreement;
    this.paymentAgreementList.forEach((paymentAgreement, index) => {
      if (index === this.selectedPaymentAgreement) {
        selectedPaymentAgreement = paymentAgreement;
      }
    });
    this.spinner.show();
    this.paymentAgreementsService
      .sendPayment(selectedPaymentAgreement)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          console.log('response', response);
          this.modal.show('paymentAgreementSuccess');
        },
        (err) => {
          console.error('err', err);
          this.spinner.hide();
        }
      );
  }

  reload() {
    this.selectedPaymentAgreement = null;
    this.loadPaymentAgreement();
  }

  // downloadExcel() {
  //   this.spinner.show();
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 2000);
  // }
}
