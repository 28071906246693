export class PaymentAgreement {
  agreement: string;
  document: string;
  name: string;
  numberOfRecords: string;
  rn: string;
  documentType: string;
  titlesToExonerate: string;
  guaranteedTitles: string;
  titlesWithoutNotice: string;
  securitiesExpiredWithoutNotice: string;
  valueToExonerate: string;
  exoneratedValue: string;
  guaranteedValue: string;
  valueWithoutNotice: string;
  valueToPay: number;
  check: boolean;

  public static fromResponse(json: any): PaymentAgreement {
    const paymentAgreement: PaymentAgreement = {
      agreement: json['ACUERDO'],
      document: json['DOCUMENTO'],
      name: json['NOMBRE'],
      numberOfRecords: json['N_REGISTROS'],
      rn: json['RN'],
      documentType: json['TIPO_DOCUMENTO'],
      titlesToExonerate: json['TITULOS_A_EXONERAR'],
      guaranteedTitles: json['TITULOS_GARANTIZADOS'],
      titlesWithoutNotice: json['TITULOS_SIN_AVISO'],
      securitiesExpiredWithoutNotice: json['TITULOS_VENCIDOS_SIN_AVISO'],
      valueToExonerate: json['VALOR_A_EXONERAR'],
      exoneratedValue: json['VALOR_EXONERADO'],
      guaranteedValue: json['VALOR_GARANTIZADO'],
      valueWithoutNotice: json['VALOR_SIN_AVISO'],
      check: false,
      valueToPay: 0,
    };
    return paymentAgreement;
  }
  public static toPaymentRequest(
    paymentAgreement: PaymentAgreement,
    documentType: string,
    documentNumber: string,
    userEmail: string
  ): any {
    return {
      id_pais: 1,
      tipo_documento: documentType,
      documento: documentNumber,
      usuario: userEmail,
      comprador_tipo_documento: paymentAgreement.documentType,
      comprador_documento: paymentAgreement.document,
      cantidad_facturas: paymentAgreement.titlesToExonerate,
      valor_a_exonerar: paymentAgreement.valueToExonerate,
      valor_abonado: paymentAgreement.valueToPay,
    };
  }
}
