export class BuyerSpace {
  agreement: string;
  alert: string;
  quoteAgreement: string;
  allocatedSpace: string;
  assignedAvailableSpace: string;
  spaceAvailable: string;
  spaceAvailableAgreement: string;
  document: string;
  status: string;
  extraSpace: string;
  availableExtraSpace: string;
  names: string;
  porcExtraSpace: string;
  documentType: string;

  public static fromResponse(json: any): BuyerSpace {
    const buyerSpace: BuyerSpace = {
      agreement: json['ACUERDO'],
      alert: json['ALERTA'],
      quoteAgreement: json['CUPO_ACUERDO'],
      allocatedSpace: json['CUPO_ASIGNADO'],
      assignedAvailableSpace: json['CUPO_ASIGNADO_DISPONIBLE'],
      spaceAvailable: json['CUPO_DISPONIBLE'],
      spaceAvailableAgreement: json['CUPO_DISPONIBLE_ACUERDO'],
      document: json['DOCUMENTO'],
      status: json['ESTADO'],
      extraSpace: json['EXTRACUPO'],
      availableExtraSpace: json['EXTRACUPO_DISPONIBLE'],
      names: json['NOMBRES'],
      porcExtraSpace: json['PORC_EXTRACUPO'],
      documentType: json['TIPO_DOCUMENTO'],
    };
    return buyerSpace;
  }
}
