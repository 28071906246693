import { Component, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ModalService } from "@services/modal.service";
import { UserAvailableSpaceRequest } from "@models/userAvailableSpaceRequest.model";
import { DocumentType } from "@models/documentType.model";

@Component({
  selector: "app-modal-not-registered",
  templateUrl: "./modal-not-registered.component.html",
  styleUrls: ["./modal-not-registered.component.scss"],
})
export class ModalNotRegisteredComponent implements OnDestroy {
  @Input() transactionUser: UserAvailableSpaceRequest;
  @Input() documentTypes: Array<DocumentType>;
  constructor(public modal: ModalService, private router: Router) {}

  ngOnDestroy() {
    this.modal.close();
  }

  close() {
    this.modal.close();
  }

  getDocumentName(documentId: String) {
    return this.documentTypes.find((documentType: DocumentType) => {
      return documentType.id == documentId;
    }).value;
  }
}
