import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-nopay-confirm',
  templateUrl: './modal-nopay-confirm.component.html',
  styleUrls: ['./modal-nopay-confirm.component.scss']
})
export class ModalNopayConfirmComponent implements OnDestroy {

  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {  }

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }

}
