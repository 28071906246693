import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPaymentState]'
})
export class PaymentStateDirective {

  @Input() value: any;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.color(this.value);
  }

  color(text: any) {
    const success = 'rgba(28, 190, 81, 1)';
    const danger = 'rgba(253, 73, 64, 1)';

    switch (text) {
      case 'PAGADA':
        this.el.nativeElement.style.color = success;
        break;

      case 'VENCIDA':
        this.el.nativeElement.style.color = danger;
        break;

      default:
        break;
    }
  }

}
