export class DocumentResponse {
    document: string;
    description: string;
    documentId: number;
    link: string;
    visible: string;

    public static fromResponse(json: any): any {
      const documentResponse: DocumentResponse = {
        document: json["DOCUMENTO"],
        description: json['DESCRIPCION'],
        documentId: json['ID_DOC'],
        link: json['LINK'],
        visible: json['VISIBLE'],
      };
      return documentResponse;
    }
  }
  