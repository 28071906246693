
export class DocumentType {
    id: string;
    value: string;

    public static fromResponse(json: any): DocumentType {
        const documentType: DocumentType = {
            id: json['ID'],
            value: json['LABEL'],
        }
        return documentType;
    }

}
