export class Department {
  id: string;
  value: string;

  public static fromResponse(json: any): Department {
    const department: Department = {
      id: json['ID'],
      value: json['LABEL'],
    };
    return department;
  }
}
