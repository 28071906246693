import { Component, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-claim-success',
  templateUrl: './modal-claim-success.component.html',
  styleUrls: ['./modal-claim-success.component.scss']
})
export class ModalClaimSuccessComponent implements OnDestroy {

  constructor(public modal: ModalService) { }


  ngOnDestroy() {
    this.modal.close();
  }

}
