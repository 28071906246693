import { Component, OnInit } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  public selectedTabId: number;
  public permissions: Array<PermissionModel>;
  showReports = false;
  showTransactionsReport = false;
  showSellersReport = false;

  public navItemTabs = [
    {
      href: '#reports',
      description: 'Reportes',
      icon: 'icon-tab-reports.svg',
      idOption: '18',
      id: 0,
    },
    {
      href: '#transactionsReport',
      description: 'Reporte de transacciones',
      icon: 'icon-tab-transactions-report.svg',
      idOption: '20',
      id: 1,
    },
    {
      href: '#sellersReport',
      description: 'Reporte de vendedores',
      icon: 'icon-tab-sellers-report.svg',
      idOption: '22',
      id: 2,
    },
  ];

  constructor(
    public helper: HelperService,
    public spinner: NgxSpinnerService,
    private usersService: UsersService
  ) {}

  showTab(idOption) {
    let showItem = false;
    switch (idOption) {
      case 18:
        showItem = this.showReports;
        break;
      case 20:
        showItem = !this.showReports && this.showTransactionsReport;
        break;
      default:
        showItem =
          !this.showReports &&
          !this.showTransactionsReport &&
          this.showSellersReport;
        break;
    }

    return showItem;
  }

  ngOnInit() {
    this.permissions = this.usersService.permissions;
    this.navItemTabs = this.navItemTabs
      .filter((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption === navItemTab.idOption
        );
        const showItem =
          permissionStatus.length > 0 &&
          permissionStatus[0].status === CONSTANTS.PERMISSIONS.ACTIVE;

        switch (navItemTab.idOption) {
          case '18':
            this.showReports = showItem;
            break;
          case '20':
            this.showTransactionsReport = showItem;
            break;
          default:
            this.showSellersReport = showItem;
            break;
        }
        return showItem;
      })
      .map((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption === navItemTab.idOption
        );
        if (permissionStatus.length > 0) {
          navItemTab['status'] = permissionStatus[0].status;
        }
        return navItemTab;
      });
    if (this.showReports) {
      this.selectedTabId = 0;
    }
    if (!this.showReports && this.showTransactionsReport) {
      this.selectedTabId = 1;
    }
    if (
      !this.showReports &&
      !this.showTransactionsReport &&
      this.showSellersReport
    ) {
      this.selectedTabId = 2;
    }
  }

  selectedTab(i: number) {
    this.selectedTabId = i;
  }
}
