
export class Bank {
    id: string;
    value: string;

    public static fromResponse(json: any): Bank {
        const bank: Bank = {
            id: json['ID'],
            value: json['LABEL'],
        }
        return bank;
    }

}
