export class Refund {
  numberOfRecords: string;
  document: string;
  documentType: string;
  valueTitle: string;
  claimValue: string;
  expiration: string;
  refundDate: string;
  refundValue: string;
  deductibleDate: string;
  deductibleValue: string;
  name: string;

  public static fromResponse(json: any): Refund {
    const paymentAgreement: Refund = {
      document: json['DOCUMENTO'],
      documentType: json['TIPO_DOCUMENTO'],
      valueTitle: json['TITULO_VALOR'],
      claimValue: json['VALOR_RECLAMADO'],
      numberOfRecords: json['N_REGISTROS'],
      expiration: json['VENCIMIENTO'],
      refundDate: json['FECHA_REINTEGRO'],
      refundValue: json['MONTO_REINTEGRO'],
      deductibleDate: json['FECHA_DEDUCIBLE'],
      deductibleValue: json['MONTO_DEDUCIBLE'],
      name: json['NOMBRE'],
    };
    return paymentAgreement;
  }
}
