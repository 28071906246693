import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-reservation-success',
  templateUrl: './modal-reservation-success.component.html',
  styleUrls: ['./modal-reservation-success.component.scss']
})
export class ModalReservationSuccessComponent implements OnInit, OnDestroy {

  @Input() approvalCode: string = '123123';

  constructor(public modal: ModalService,
              private router: Router) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.modal.close();
  }

  goToStart() {
    this.router.navigateByUrl('/pages/transacciones');
  }

}
