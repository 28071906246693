import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-double-progress-bar',
  templateUrl: './double-progress-bar.component.html',
  styleUrls: ['./double-progress-bar.component.scss']
})
export class DoubleProgressBarComponent {
  
  @Input() title: string;
  @Input() year1: number;
  @Input() year2: number;
  @Input() year1Value: number;
  @Input() year2Value: number;
  @Input() isMoney: boolean;

  constructor() { }


}
