import { Component, OnInit, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-user-disable',
  templateUrl: './modal-user-disable.component.html',
  styleUrls: ['./modal-user-disable.component.scss']
})
export class ModalUserDisableComponent implements OnDestroy {

  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {  }

    ngOnDestroy() {
      this.modal.close();
    }

    accept() {
      this.modal.close();
      this.confirm.emit();
    }

}