import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';

@Component({
  selector: 'app-contact-channels',
  templateUrl: './contact-channels.component.html',
  styleUrls: ['./contact-channels.component.scss'],
})
export class ContactChannelsComponent implements OnInit {
  @Input() clientStudyForm: ClientStudyRequest;
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  constructor() {}

  ngOnInit() {}

  onBlur($event) {
    this.valueChanges.emit();
  }
}
