import {
  Component,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalService } from '@services/modal.service';
import * as moment from 'moment';
@Component({
  selector: 'app-modal-transaction-confirm',
  templateUrl: './modal-transaction-confirm.component.html',
  styleUrls: ['./modal-transaction-confirm.component.scss'],
})
export class ModalTransactionConfirmComponent implements OnDestroy {
  @Input() name: string;
  @Input() transactionValue: number;
  @Input() transactionNumber: string;
  @Input() expirationDate: Date;
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {}

  ngOnInit() {
    console.log('name', this.name);
    console.log('transactionValue', this.transactionValue);
    console.log('transactionNumber', this.transactionNumber);
    console.log('expirationDate', this.expirationDate);
  }

  ngOnDestroy() {
    this.modal.close();
  }

  getDate(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }
}
