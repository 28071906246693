export class DocumentFile {
  id: string;
  description: string;
  documentName: string;
  url: string;
  visible: string;

  public static fromResponse(json: any): DocumentFile {
    const documentFile: DocumentFile = {
      id: json['ID_DOC'],
      description: json['DESCRIPCION'],
      documentName: json['DOCUMENTO'],
      url: json['LINK'],
      visible: json['VISIBLEID'],
    };
    return documentFile;
  }
}
