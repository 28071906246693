import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export class MyPaymentsRequest {
  idCountry: number = 1;
  documentType?: string;
  documentNumber?: string;
  page: string = '1';
  records: string = '10';

  public static toRequest(payment: MyPaymentsRequest): any {
    return {
      id_pais: payment.idCountry,
      tipo_documento: payment.documentType,
      documento: payment.documentNumber,
      pagina: payment.page,
      registros: payment.records,
    };
  }
}

export class MyPaymentExportRequest {
  idCountry: number = 1;
  documentType?: string;
  documentNumber?: string;
  bill?: string;

  public static toRequest(payment: MyPaymentExportRequest): any {
    return {
      id_pais: payment.idCountry,
      tipo_documento: payment.documentType,
      documento: payment.documentNumber,
      factura: payment.bill,
    };
  }
}

export class MyPaymentsResponse {
  netsuite: string;
  state: string;
  internalInvoice: string;
  productCode: string;
  iva: number;
  billNumber: string;
  date: string;
  billing: string;
  value: number;
  // to pay
  affiliate?: string;
  lastName?: string;
  application?: string;
  contract?: string;
  email?: string;
  document?: string;
  documentType?: string;
  originUrl?: string;
  returnUrl?: string;
  numberOfRecords?: string;

  public static toRequest(payment: MyPaymentsResponse): any {
    return {
      id_pais: 1,
      vads_cust_id: payment.netsuite,
      vads_order_info2: payment.billNumber,
      vads_order_info3: payment.internalInvoice,
      vads_cust_email: payment.email,
      documento: payment.document,
      tipo_documento: payment.documentType,
      vads_cust_first_name: payment.affiliate,
      vads_cust_last_name: payment.lastName,
      vads_shop_name: payment.application,
      vads_shop_url: payment.originUrl,
      vads_amount: payment.value,
      vads_order_id: payment.netsuite,
      vads_url_return: payment.returnUrl,
    };
  }

  public static fromResponse(json: MyPaymentsResponse): any {
    return {
      affiliate: json['AFILIADO'],
      netsuite: json['NETSUITE'],
      state: json['ESTADO'],
      internalInvoice: json['FACTURA_INTERNA'],
      productCode: json['CODIGO_PRODUCTO'],
      iva: json['IVA'],
      billNumber: json['NUMERO_FACTURA'],
      date: moment(json['FECHA'], 'DD/MM/YYYY').format('MM/DD/YYYY'),
      billing: (json['FACTURACION'] = json['FACTURACION']
        ? moment(json['FACTURACION'], 'DD/MM/YYYY').format('MM/DD/YYYY')
        : json['FACTURACION']),
      value: json['VALOR'],
      // to pay
      lastName: json['APELLIDO'],
      application: json['APLICACION'],
      contract: json['CONTRATO'],
      email: json['CORRREO'],
      document: json['DOCUMENTO'],
      documentType: json['TIPO_DOCUMENTO'],
      originUrl: json['URL_ORIGEN'],
      returnUrl: json['URL_RETORNO'],
      numberOfRecords: json['N_REGISTROS'],
    };
  }
}
