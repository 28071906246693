import { MyPaymentsService } from '@services/my-payments.service';
import {
  MyPaymentsRequest,
  MyPaymentsResponse,
  MyPaymentExportRequest,
} from '@models/myPayments.model';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneratePaymentRequest } from '@models/generatePayment.model';
import { ModalService } from '@services/modal.service';
import { NetsuiteRequest } from '@models/netsuiteBill.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-bill-to-pay',
  templateUrl: './tab-bill-to-pay.component.html',
  styleUrls: ['./tab-bill-to-pay.component.scss'],
})
export class TabBillToPayComponent implements OnInit {
  @Input() toPay: Array<MyPaymentsResponse>;
  @ViewChild('payzenForm') payzenForm: ElementRef;
  public pdfBase64: string;
  public payzenValues: Array<any>;
  public selectedBillNumber: string;
  public selectedBill: MyPaymentsResponse;
  public activeButtons = false;
  public myPaymentsRequest: MyPaymentsRequest = new MyPaymentsRequest();
  public generatePaymentRequest: GeneratePaymentRequest =
    new GeneratePaymentRequest();
  public myPaymentExportRequest: MyPaymentExportRequest =
    new MyPaymentExportRequest();
  public netsuiteRequest: NetsuiteRequest = new NetsuiteRequest();
  public multipleBillForPay: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public modal: ModalService,
    private myPaymentsService: MyPaymentsService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.myPayments();
  }

  myPayments() {
    this.spinner.show();
    this.myPaymentsService.getMyPayments(this.myPaymentsRequest).subscribe(
      (resp: Array<MyPaymentsResponse>) => {
        this.toPay = resp.filter(({ state }) => state === 'POR PAGAR');
        if (this.toPay && this.toPay.length === 1) {
          this.selectedBillNumber = this.toPay[0].billNumber;
          this.multipleBillForPay = false;
          this.netsuiteBill();
        } else if (this.toPay && this.toPay.length > 1) {
          this.multipleBillForPay = true;
        }
      },
      (err: any) => {
        console.error(err);
        this.multipleBillForPay = false;
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  netsuiteBill(billNumber?: string) {
    this.spinner.show();
    this.selectedBillNumber = billNumber ? billNumber : this.selectedBillNumber;
    this.myPaymentsService
      .getNetsuiteBill(this.netsuiteRequest, this.selectedBillNumber)
      .subscribe(
        (base64: string) => {
          if (base64 && !this.multipleBillForPay) {
            this.pdfBase64 = base64;
          } else if (base64 && this.multipleBillForPay) {
            this.modal.show('pdfBill', {
              base64,
            });
          }
        },
        (err: any) => {
          console.error(err);
          this.toastr.error('Esta factura no está disponible.', '', {
            timeOut: 3000,
          });
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  handleItemSelected(bill: MyPaymentsResponse) {
    this.selectedBill = bill;
    this.selectedBillNumber = this.selectedBill.billNumber;
    this.activeButtons = true;
  }

  exportMyPaymentDetails() {
    this.spinner.show();
    const { internalInvoice } = this.selectedBill;
    this.myPaymentExportRequest.bill = internalInvoice;
    this.myPaymentsService
      .exportMyPayment(this.myPaymentExportRequest)
      .subscribe(
        (res: any) => {
          const blob = new Blob([res], {
            type: 'application/ms-excel',
          });
          saveAs(blob, `${this.selectedBillNumber}.xlsx`);
        },
        (err: any) => {
          console.error(err);
          this.toastr.error('No se pudo exportar el informe.', '', {
            timeOut: 3000,
          });
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  generatePayment() {
    this.spinner.show();
    const { billNumber, date, iva, value, productCode } = this.selectedBill;
    this.generatePaymentRequest.bill = billNumber;
    this.generatePaymentRequest.dueDate = date;
    this.generatePaymentRequest.iva = iva;
    this.generatePaymentRequest.totalIncludeIva = value;
    this.generatePaymentRequest.productCode = productCode;
    this.myPaymentsService
      .generatePayment(this.generatePaymentRequest)
      .subscribe(
        (url: string) => {
          window.open(url, '_blank');
        },
        (err: any) => {
          console.error(err);
          this.toastr.error('No se pudo generar el pago.', '', {
            timeOut: 3000,
          });
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  generatePayzenPayment() {
    this.spinner.show();
    this.myPaymentsService.generatePayzenPayment(this.selectedBill).subscribe(
      (payzenValues: Array<any>) => {
        this.payzenValues = payzenValues;
        this.cdRef.detectChanges();
      },
      (err: any) => {
        console.error(err);
        this.toastr.error('No se pudo generar el pago.', '', {
          timeOut: 3000,
        });
      },
      () => {
        this.spinner.hide();
        if (this.payzenValues && this.payzenValues.length > 0) {
          this.payzenForm.nativeElement.submit();
        }
      }
    );
  }
}
