import { RolByIdPipe } from './../pipes/rol-by-id.pipe';
import { FallbackImgDirective } from './../directives/fallback-img.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentTypePipe } from '../pipes/document-type.pipe';
import { BgUrlPipe } from '../pipes/bg-url.pipe';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AlertColorDirective } from '../directives/alert-color.directive';
import { OutsideDirective } from '../directives/outside.directive';
import { UppercaseDirective } from '../directives/uppercase.directive';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { ReplacePipe } from '../pipes/replace.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { SumPipe } from '../pipes/sum.pipe';
import { TooltipDirective } from '../directives/tooltip.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';

/*DatePicker*/
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OwlDateTimeIntl,
  OWL_DATE_TIME_LOCALE,
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
} from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY hh:mm a',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'hh:mm a',
  monthYearLabel: 'MMM-YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM-YYYY',
};

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentStateDirective } from '../directives/payment-state.directive';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TableEmptyStateComponent } from './components/table-empty-state/table-empty-state.component';
registerLocaleData(localeEsCO, 'es-Co');

export class DefaultIntl extends OwlDateTimeIntl {
  rangeFromLabel = 'Fecha inicial';
  rangeToLabel = 'Fecha final';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
  prevMultiYearLabel = '21 años atrás';
  nextMultiYearLabel = 'Próximos 21 años';
}

@NgModule({
  declarations: [
    DocumentTypePipe,
    BgUrlPipe,
    RolByIdPipe,
    AlertColorDirective,
    OutsideDirective,
    UppercaseDirective,
    OnlyNumberDirective,
    FallbackImgDirective,
    ReplacePipe,
    FilterPipe,
    SumPipe,
    TooltipDirective,
    PaymentStateDirective,
    TableEmptyStateComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxExtendedPdfViewerModule,
    NgxSpinnerModule,
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UiSwitchModule.forRoot({
      // size: 'small',
      color: 'rgb(61, 160, 48)',
      switchColor: '#FFFFFF',
      defaultBgColor: 'rgb(151, 151, 151)',
      defaultBoColor: '#dadada',
      checkedLabel: 'Si',
      uncheckedLabel: 'No',
    }),
    NgxDropzoneModule,
  ],
  providers: [
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
      useValue: 'es',
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: LOCALE_ID, useValue: 'es-Co' },
  ],
  exports: [
    DocumentTypePipe,
    RolByIdPipe,
    BgUrlPipe,
    FormsModule,
    NgxSpinnerModule,
    ImageCropperModule,
    AlertColorDirective,
    OutsideDirective,
    UppercaseDirective,
    OnlyNumberDirective,
    FallbackImgDirective,
    ReplacePipe,
    UiSwitchModule,
    FilterPipe,
    SumPipe,
    TooltipDirective,
    PaymentStateDirective,
    NgxDropzoneModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxExtendedPdfViewerModule,
    TableEmptyStateComponent,
  ],
})
export class SharedModule {}
