import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-progress-upload-document',
  templateUrl: './progress-upload-document.component.html',
  styleUrls: ['./progress-upload-document.component.scss']
})
export class ProgressUploadDocumentComponent implements OnInit {

  @Input() id: number;
  @Input() title: string;
  @Input() progress: number;
  @Input() required: boolean;
  @ViewChild ('inputFile') inputFileValue: any;

  public file: File;
  public fileName: string;

  constructor() { }

  ngOnInit() {
  }

 change(file: any) {
    this.progress = 50;
    this.file = file;
    this.fileName = file['name'];
    setTimeout(() => {
      this.progress = 100;
    }, 700);
  }

  deleteFile(id: number) {
    this.inputFileValue.nativeElement.value = '';
    this.progress = 0;
    this.file = undefined;
    this.fileName = '';
  }

}
