import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '@models/user.model';
import { PaymentAgreementsListRequest } from '@models/paymentAgreementListRequest.model';
import { PaymentAgreement } from '@models/paymentAgreement.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentAgreementsService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getPaymentAgreements(page: string, limit: string, search: string) {
    let paymentAgreementListRequest: PaymentAgreementsListRequest = {
      search,
      documentNumber: this.identity.userInfo.document,
      documentType: this.identity.userInfo.documentType,
      page,
      records: limit,
      idContry: '1',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: PaymentAgreementsListRequest.toRequest(
        paymentAgreementListRequest
      ),
      headers,
    };
    return this.http
      .get(`${this.urlApi}/pr_garantias_acuerdo_anticipado_v2`, options)
      .pipe(
        map((resp: any) => {
          const paymentAgreementList: Array<PaymentAgreement> = resp[
            'GARANTIAS_ACUERDO_ANTICIPADO'
          ]
            ? resp['GARANTIAS_ACUERDO_ANTICIPADO'].map((paymentAgreement) =>
                PaymentAgreement.fromResponse(paymentAgreement)
              )
            : [];
          return paymentAgreementList;
        })
      );
  }

  sendPayment(paymentAgreement: PaymentAgreement) {
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: PaymentAgreement.toPaymentRequest(
        paymentAgreement,
        this.identity.userInfo.documentType,
        this.identity.userInfo.document,
        this.identity.username
      ),
      headers,
    };
    return this.http
      .post(`${this.urlApi}/pr_carga_masiva_acuerdo_anticipado`, {}, options)
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
