import { ReportsService } from '@services/reports.service';
import { HelperService } from '@services/helper.service';
import {
  SellerReportResponse,
  SellerReportRequest,
} from '@models/sellersReport.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationComponent } from '@components/layout/pagination/pagination.component';
import { ModalService } from '@app/services/modal.service';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
@Component({
  selector: 'app-tab-sellers-report',
  templateUrl: './tab-sellers-report.component.html',
  styleUrls: ['./tab-sellers-report.component.scss'],
})
export class TabSellersReportComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public totalPages: number = 1;
  public pageLimit: number = 5;
  public reports: Array<SellerReportResponse>;

  public reportRequest: SellerReportRequest = new SellerReportRequest();
  public permissions: Array<PermissionModel>;
  public constants = CONSTANTS;
  public donwloadGeneralReport = 'A';
  public donwloadDetailedReport = 'A';

  constructor(
    public spinner: NgxSpinnerService,
    private reportsService: ReportsService,
    public modal: ModalService,
    public helper: HelperService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.sellersReport(1);
    this.permissions = this.usersService.permissions;
    const generalPermissionStatus = this.permissions.filter(
      (permission) => permission.idOption === '23'
    );
    if (generalPermissionStatus.length > 0) {
      this.donwloadGeneralReport = generalPermissionStatus[0].status;
    }
    const detailedPermissionStatus = this.permissions.filter(
      (permission) => permission.idOption === '24'
    );
    if (detailedPermissionStatus.length > 0) {
      this.donwloadDetailedReport = detailedPermissionStatus[0].status;
    }
  }

  pageSelected({ currentPage }) {
    this.sellersReport(currentPage);
  }

  onSearch(documentNumber: string) {
    this.reportRequest.userDocumentNumber = documentNumber || '0';
    this.sellersReport(1);
  }

  sellersReport(currentPage: number) {
    this.spinner.show();
    this.reportRequest.page = currentPage;
    this.reportsService.getSellersReport(this.reportRequest).subscribe(
      (reports: Array<SellerReportResponse>) => {
        this.reports = reports;
        this.totalPages = this.helper.getAmountOfPages(reports, this.pageLimit);
        this.spinner.hide();
      },
      (err: any) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  downloadSellersExcel(type: string) {
    this.spinner.show();
    this.reportsService.exportSellers(this.reportRequest, type).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], {
          type: 'application/ms-excel',
        });
        saveAs(blob, `informeVendedores.xlsx`);
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.modal.show('error', {
          title: `No se pudo descargar el reporte.`,
          message: 'Por favor intente más tarde',
        });
      }
    );
  }
}
