import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  public userName: string;
  public numberTransactions: number;
  public afifiliateName: string;
  public cityOffice: string;
  public documentType: string = 'N';
  public money = 123456789;

  public DataSelectFake = [
    { value: 1, description: 'Opción 1' },
    { value: 2, description: 'Opción 2' },
    { value: 3, description: 'Opción 3' },
  ];

  public data1 = {
    tipo_documento: '',
    documento: '',
    codigo: ''
  };

  // Forms
  // @ViewChild('formCheck') formCheck: NgForm;

  constructor(private spinner: NgxSpinnerService,
    public helper: HelperService,
    private router: Router,
    public toastr: ToastrService,
    private modal: ModalService) { }

  ngOnInit() {
  }

  transaction() {
    this.modal.show('transactionSuccess');
  }


}
