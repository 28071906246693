import {
  TransactionsReportRequest,
  TransactionsReportResponse,
} from '@models/transactionsReport.model';
import { ReportsService } from '@services/reports.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { PaginationComponent } from '@components/layout/pagination/pagination.component';
import * as moment from 'moment';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
@Component({
  selector: 'app-tab-transactions-report',
  templateUrl: './tab-transactions-report.component.html',
  styleUrls: ['./tab-transactions-report.component.scss'],
})
export class TabTransactionsReportComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public totalPages: number = 1;
  public pageLimit: number = 5;
  public minInput: Date = moment().startOf('year').subtract(1, 'year').toDate();
  public reports: Array<TransactionsReportResponse>;

  public reportRequest: TransactionsReportRequest = {
    endDate: moment().toDate(),
    startDate: moment().subtract(15, 'days').toDate(),
    page: 1,
  };

  public permissions: Array<PermissionModel>;
  public constants = CONSTANTS;
  public donwloadReport = 'A';

  constructor(
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private reportsService: ReportsService,
    public modal: ModalService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.transactionsReport(1);
    this.permissions = this.usersService.permissions;
    const permissionStatus = this.permissions.filter(
      (permission) => permission.idOption === '21'
    );
    if (permissionStatus.length > 0) {
      this.donwloadReport = permissionStatus[0].status;
    }
  }

  pageSelected({ currentPage }) {
    this.transactionsReport(currentPage);
  }

  search(dateRange: any) {
    if (dateRange[0]) {
      this.reportRequest.startDate = dateRange[0];
    }
    if (dateRange[1]) {
      this.reportRequest.endDate = dateRange[1];
    }
    if (dateRange[0] && dateRange[1]) {
      this.transactionsReport(1);
    }
  }

  onSearch(documentNumber: string) {
    if (documentNumber && documentNumber.length < 1) {
      this.reportRequest.buyerDocumentNumber = documentNumber || '0';
      this.transactionsReport(1);
      return;
    }
    this.reportRequest.buyerDocumentNumber = documentNumber;
    this.transactionsReport(1);
  }

  transactionsReport(currentPage: number) {
    this.spinner.show();
    this.reportRequest.page = currentPage;
    this.reportsService.getTransactionsReport(this.reportRequest).subscribe(
      (report: Array<TransactionsReportResponse>) => {
        this.reports = report;
        this.totalPages = this.helper.getAmountOfPages(report, this.pageLimit);
        this.spinner.hide();
      },
      (err: any) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  downloadTransactionsExcel() {
    this.spinner.show();
    this.reportsService.exportTransactions(this.reportRequest).subscribe(
      (res: any) => {
        const blob = new Blob([res], {
          type: 'application/ms-excel',
        });
        saveAs(blob, `reporteTransacciones.xlsx`);
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.modal.show('error', {
          title: `No se pudo realizar la descarga.`,
          message: 'Por favor intente más tarde',
        });
      }
    );
  }

  noPay() {
    this.modal.show('nopayConfirm');
  }

  downloadExcel() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }
}
