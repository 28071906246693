import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-invalid-code',
  templateUrl: './modal-invalid-code.component.html',
  styleUrls: ['./modal-invalid-code.component.scss']
})
export class ModalInvalidCodeComponent implements OnInit, OnDestroy {

  // @Input() data: any;

  constructor(public modal: ModalService,
              private router: Router) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.modal.close();
  }

  close() {
    this.modal.close();
  }

}
