import { Component } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.scss'],
})
export class MyPaymentsComponent {
  public permissions: Array<PermissionModel>;
  invoiceHistory = false;
  billsToPay = false;

  public navItemTabs = [
    {
      href: '#bill-to-pay',
      description: 'Factura por pagar',
      icon: 'icon-tab-bill-to-pay.svg',
      idOption: '27',
    },
    {
      href: '#bill-history',
      description: 'Historial de facturas',
      icon: 'icon-tab-bill-history.svg',
      idOption: '28',
    },
  ];

  constructor(
    public spinner: NgxSpinnerService,
    public helper: HelperService,
    public modal: ModalService,
    private usersService: UsersService
  ) {}

  showTab(idOption) {
    let showItem = false;
    switch (idOption) {
      case 27:
        showItem = this.billsToPay;
        break;
      default:
        showItem = !this.billsToPay && this.invoiceHistory;
        break;
    }
    return showItem;
  }

  ngOnInit() {
    this.permissions = this.usersService.permissions;
    this.navItemTabs = this.navItemTabs
      .filter((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption === navItemTab.idOption
        );
        const showItem =
          permissionStatus.length > 0 &&
          permissionStatus[0].status === CONSTANTS.PERMISSIONS.ACTIVE;

        switch (navItemTab.idOption) {
          case '27':
            this.billsToPay = showItem;
            break;
          default:
            this.invoiceHistory = showItem;
            break;
        }
        return showItem;
      })
      .map((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption === navItemTab.idOption
        );
        if (permissionStatus.length > 0) {
          navItemTab['status'] = permissionStatus[0].status;
        }
        return navItemTab;
      });
  }
}
