export class Reserve {
  daysExpiration: string;
  document: string;
  reservationDate: string;
  numberOfRecords: string;
  rn: string;
  documentType: string;
  name: string;
  totalValue: string;
  queryCode: string;
  reservationCode: string;
  check: boolean;

  public static fromResponse(json: any): Reserve {
    const reserve: Reserve = {
      daysExpiration: json['DIAS_VENCIMIENTO'],
      document: json['DOCUMENTO'],
      name: json['NOMBRE'],
      reservationDate: json['FECHA_RESERVA'],
      numberOfRecords: json['N_REGISTROS'],
      rn: json['RN'],
      documentType: json['TIPO_DOCUMENTO'],
      totalValue: json['VALOR_TOTAL'],
      queryCode: json['CODIGO_CONSULTA'],
      reservationCode: json['CODIGO_RESERVA'],
      check: false,
    };
    return reserve;
  }
}
