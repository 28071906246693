import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PubSubService {
  public messageSent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public sendMessage(message: any): void {
    this.messageSent.emit(message);
  }
}
