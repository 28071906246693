import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { TransactionService } from '@services/transaction.service';
import { DocumentType } from '@models/documentType.model';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { CONSTANTS } from '@config/constants';

@Component({
  selector: 'app-tab-individual-transaction',
  templateUrl: './tab-individual-transaction.component.html',
  styleUrls: ['./tab-individual-transaction.component.scss'],
})
export class TabIndividualTransactionComponent implements OnInit {
  @ViewChild('formCheck') formCheck: NgForm;

  public documentTypes: Array<DocumentType>;
  public showError: boolean = false;
  public errorType: String = '';
  public errorDocument: String = '';
  public transactionUser: UserAvailableSpaceRequest = {
    idContry: '1',
    sellerDocumentType: '',
    sellerDocumentNumber: '',
    documentType: '',
    documentNumber: '',
    queryCode: '',
  };

  public userAvailableSpaceResponse: UserAvailableSpaceResponse;

  constructor(
    public helper: HelperService,
    public modal: ModalService,
    public transaction: TransactionService,
    private spinner: NgxSpinnerService,
    public router: Router
  ) {}

  ngOnInit() {
    // this.helper.markForm(this.formCheck);
    this.documentTypes = this.helper.documentTypes;
  }

  checkQuota() {
    this.showError = false;
    this.errorType = '';
    this.spinner.show();
    // if (form && form.invalid) { return; }
    this.transaction.checkQuota(this.transactionUser).subscribe(
      (userAvailableSpaceResponse: UserAvailableSpaceResponse) => {
        this.userAvailableSpaceResponse = userAvailableSpaceResponse;
        this.spinner.hide();
        if (
          (userAvailableSpaceResponse.statusCode ==
            CONSTANTS.TRANSACTIONS.ACTIVE_CODE &&
            userAvailableSpaceResponse.buyerSpace) ||
          (userAvailableSpaceResponse.buyerSpace &&
            userAvailableSpaceResponse.buyerSpace.alert ==
              CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT)
        ) {
          if (
            userAvailableSpaceResponse.buyerSpace.status ==
              CONSTANTS.TRANSACTIONS.ENABLED ||
            userAvailableSpaceResponse.buyerSpace.status ==
              CONSTANTS.TRANSACTIONS.ALLOW_WITHOUT_QUOTE ||
            userAvailableSpaceResponse.buyerSpace.alert ==
              CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT
          ) {
            this.router.navigateByUrl(
              `/pages/transacciones/transaccion-individual/${this.transactionUser.sellerDocumentType}/${this.transactionUser.sellerDocumentNumber}/${this.transactionUser.queryCode}`
            );
          } else if (
            userAvailableSpaceResponse.buyerSpace.status ===
            CONSTANTS.TRANSACTIONS.LOCKED
          ) {
            this.modal.show('transactionLocked');
          } else {
            this.modal.show('requestSuspendedStatus');
          }
        } else if (
          userAvailableSpaceResponse.statusCode ==
            CONSTANTS.TRANSACTIONS.ACTIVE_CODE &&
          !userAvailableSpaceResponse.buyerSpace
        ) {
          // this.modal.show('notRegistered');
          this.errorType = 'error';
          this.errorDocument = `${this.transactionUser.sellerDocumentType} ${this.transactionUser.sellerDocumentNumber}`;
        } else if (
          userAvailableSpaceResponse.statusCode ==
          CONSTANTS.TRANSACTIONS.INVALID_CODE
        ) {
          this.showError = true;
        } else {
          this.modal.show('requestSuspendedStatus');
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
