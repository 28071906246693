import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';

@Component({
  selector: 'app-modal-locked',
  templateUrl: './modal-locked.component.html',
  styleUrls: ['./modal-locked.component.scss'],
})
export class ModalLockedComponent implements OnDestroy {
  @Input() userAvailableSpace: UserAvailableSpaceResponse;

  constructor(public modal: ModalService, private router: Router) {}

  ngOnDestroy() {
    this.modal.close();
  }

  close() {
    this.modal.close();
  }
}
