import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFallbackImg]'
})
export class FallbackImgDirective {

  @Input() appImgFallback: string;

  constructor(private elRef :ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const fallbackImg = 'assets/img/administration/user.svg';
    const element: HTMLImageElement = <HTMLImageElement> this.elRef.nativeElement;
    element.src = this.appImgFallback || fallbackImg;
  }

}
