export class NetsuiteRequest {
    idCountry: number = 1;
    documentType?: string;
    documentNumber?: string;
    urlEndpoint: string;
    strService: string;
    xmlParams: string;

    public static toRequest(json: NetsuiteRequest): any {
       return {
            id_pais: json['idCountry'],
            tipo_documento: json['documentType'],
            documento: json['documentNumber'],
            UrlEnpoint: json['urlEndpoint'],
            strServicio: json['strService'],
            xmlParametros: json['xmlParams']
        };
    }

}