export class Owner {
  documentType?: string;
  documentNumber?: string;
  firstName?: string;
  secondName?: string;
  firstLastName?: string;
  secondLastName?: string;

  public static fromResponse(json: any): Owner {
    const owner: Owner = {
      documentType: json['tipo_documento_socio'],
      documentNumber: json['documento_socio'],
      firstName: json['nom1_socio'],
      secondName: json['nom2_socio'],
      firstLastName: json['apel1_socio'],
      secondLastName: json['apel2_socio'],
    };
    return owner;
  }

  public static toRequest(owner: Owner): any {
    return {
      tipo_documento_socio: owner.documentType,
      documento_socio: owner.documentNumber,
      nom1_socio: owner.firstName,
      nom2_socio: owner.secondName,
      apel1_socio: owner.firstLastName,
      apel2_socio: owner.secondLastName,
    };
  }
}
