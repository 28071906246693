import { Component, Input, NgZone, OnInit } from '@angular/core';
import { CONSTANTS } from '@config/constants';
import { Bank } from '@models/bank.model';
import { Reserve } from '@models/reserve.model';
import { TransactionDue } from '@models/transactionDue.model';
import { TransactionRequest } from '@models/transactionRequest.model';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { TransactionService } from '@services/transaction.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tab-check',
  templateUrl: './tab-check.component.html',
  styleUrls: ['./tab-check.component.scss'],
})
export class TabCheckComponent implements OnInit {
  // Properties
  @Input() transactionUser: UserAvailableSpaceRequest;
  @Input() userAvailableSpace: UserAvailableSpaceResponse;
  @Input() reserveList: Array<Reserve>;

  public total = 0;

  public transactionRequest: TransactionRequest = {
    idContry: '1',
    sellerDocumentType: '',
    sellerDocumentNumber: '',
    documentType: '',
    documentNumber: '',
    queryCode: '',
    reservationCode: '',
    transactionType: '',
    titleValue: '',
    username: '',
    expirationDate: moment(new Date()).toDate(),
    transactionValue: null,
    bank: '',
    acount: '',
    municipality: '0',
    phone: '0',
    celPhone: '0',
    addres: '.',
    transactionDues: [],
  };

  //   id_pais: 1
  // comprador_tipo_documento: N
  // tipo_documento: N
  // documento: 8600284621
  // comprador_documento: 8301342461
  // usuario: CC80036832
  // tipo_transaccion: D
  // num_titulovalor: 1
  // fechavence: 20201221
  // valor_transaccion: 100
  // StrJArray: [{"fecha":"20201221","valor":"100","titulo":1}]
  // banco: 59
  // cuenta: 24097074380
  // lista_municipio: 0
  // telefono: 0
  // celular: 0
  // direccion: .
  // codigo_consulta: 517144

  public minDate = moment(new Date()).toDate();
  public approvalCode: string;
  public errorMessage: string;
  public constants: any = CONSTANTS;
  public numberInstallments;

  public banks: Array<Bank>;

  public checkTypes = [
    {
      value: CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE_ID,
      label: CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE,
    },
    {
      value: CONSTANTS.TRANSACTIONS.CHECK.POSTDATED_ID,
      label: CONSTANTS.TRANSACTIONS.CHECK.POSTDATED,
    },
  ];

  public individualTransaction = true;

  public radioSelected: '';

  public installments: Array<TransactionDue> = [];

  constructor(
    public helper: HelperService,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private transactionService: TransactionService,
    public zone: NgZone
  ) {}

  ngOnInit() {
    this.banks = this.helper.banks;
    this.transactionRequest.reservationCode =
      this.transactionUser.reservationCode || '';
  }

  changeInstallments() {
    let installments: Array<TransactionDue> = [];
    const fee = this.transactionRequest.transactionValue
      ? this.transactionRequest.transactionValue / this.numberInstallments
      : 0;
    let initialDate = moment(new Date());
    for (let index = 0; index < this.numberInstallments; index++) {
      initialDate = initialDate.add(1, 'month');
      installments.push({
        date: this.transactionRequest.expirationDate,
        dateString: initialDate.format('DD/MM/YYYY'),
        title: `${index + 1}`,
        value: fee,
        check: false,
      });
    }
    this.installments = installments;
  }

  checkValidDate() {
    let isValid = true;
    for (let index = 0; index < this.installments.length; index++) {
      const installment = this.installments[index];
      const installmentDate = moment(installment.dateString);
      if (index > 0) {
        const lastInstallment = this.installments[index - 1];
        const lastInstallmentDate = moment(lastInstallment.dateString);
        if (installmentDate <= lastInstallmentDate) {
          isValid = false;
          break;
        }
      }
    }
    // console.log(
    //   'this.installments',
    //   this.installments[this.installments.length - 1]
    // );
    return isValid;
  }

  dateTextChange($event, index) {
    this.installments[index].dateString = $event.target.value;
  }

  selectedReservationCodeValue() {
    return parseFloat(
      this.reserveList.find((reserve: Reserve) => {
        return (
          reserve.reservationCode == this.transactionRequest.reservationCode
        );
      }).totalValue
    );
  }

  transactionCheck() {
    this.modal.show('transactionConfirm');
  }

  radioSelectedItem(value: any) {}

  getTotal(i) {
    if (i === 0) {
      const firstValue = this.installments[i].value;
      const totalValue = this.transactionRequest.transactionValue;
      const otherValue =
        (totalValue - firstValue) / (this.installments.length - 1);
      this.installments.forEach(
        (installment: TransactionDue, index: number) => {
          if (index !== 0) {
            this.installments[index].value = otherValue;
          }
        }
      );
    }
  }

  setItemValue(index: number, $event) {
    this.installments[index].value = this.helper.currencyInputChanged(
      $event.target.textContent
    );
    this.moveCursorToEnd($event.target);
  }

  moveCursorToEnd(el) {
    if (el.innerText && document.createRange) {
      window.setTimeout(() => {
        let selection = document.getSelection();
        let range = document.createRange();

        range.setStart(el.childNodes[0], el.innerText.length);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }, 1);
    }
  }

  // dateTextInitChange($event) {
  //   this.initDate =
  //     $event.target.value.length != 10
  //       ? moment().toDate()
  //       : moment($event.target.value, 'DD/MM/YYYY').toDate();
  //   this.selectedMoments = [this.initDate, this.finalDate];
  // }

  // maskDate(index: number, $event) {
  //   let v = $event;
  //   if (v.match(/^\d{2}$/) !== null) {
  //     this.installments[index].dateString = v + '/';
  //   } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
  //     this.installments[index].dateString = v + '/';
  //   }

  //   if ($event.length >= 10) {
  //     this.zone.run(
  //       () => (this.installments[index].dateString = $event.substring(0, 10))
  //     );
  //   }
  // }

  clipboardEventHandler($event) {
    // Chrome , Firefox
    if ($event.target.textContent.length > 10 && $event.keyCode !== 8) {
      $event.preventDefault();
    }
  }

  sendTransaction() {
    this.transactionRequest.sellerDocumentNumber = this.transactionUser.sellerDocumentNumber;
    this.transactionRequest.sellerDocumentType = this.transactionUser.sellerDocumentType;
    this.transactionRequest.documentNumber = this.transactionUser.documentNumber;
    this.transactionRequest.documentType = this.transactionUser.documentType;
    this.transactionRequest.queryCode = this.transactionUser.queryCode;
    let transactionDues: Array<TransactionDue> = [];
    if (
      this.transactionRequest.transactionType ==
      CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE_ID
    ) {
      transactionDues = [
        {
          date: this.transactionRequest.expirationDate,
          dateString: '',
          title: this.transactionRequest.titleValue,
          value: this.transactionRequest.transactionValue,
          check: false,
        },
      ];
    } else {
      this.transactionRequest.titleValue = this.installments[0].title;
      transactionDues = this.installments;
      for (let index = 0; index < transactionDues.length; index++) {
        transactionDues[index].date = moment(
          transactionDues[index].dateString,
          'DD/MM/YYYY'
        ).toDate();
      }
    }

    this.transactionRequest.transactionDues = transactionDues;
    this.spinner.show();
    this.transactionService
      .createTransaction(this.transactionRequest)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.error) {
            this.errorMessage = response.message;
            this.modal.show('transactionDenied');
          } else {
            this.approvalCode = response.approvalCode;
            this.modal.show('transactionSuccess');
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
}
