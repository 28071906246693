export class UserAvailableSpaceRequest {
  idContry: string;
  sellerDocumentType: string;
  sellerDocumentNumber: string;
  reservationCode?: string;
  documentType: string;
  documentNumber: string;
  queryCode: string;

  public static fromResponse(json: any): UserAvailableSpaceRequest {
    const userAvailableSpaceRequest: UserAvailableSpaceRequest = {
      idContry: json['id_pais'],
      sellerDocumentType: json['comprador_tipo_documento'],
      sellerDocumentNumber: json['comprador_documento'],
      documentType: json['tipo_documento'],
      documentNumber: json['documento'],
      queryCode: json['codigo_consulta'],
    };
    return userAvailableSpaceRequest;
  }

  public static toRequest(
    userAvailableSpaceRequest: UserAvailableSpaceRequest
  ): any {
    return {
      id_pais: userAvailableSpaceRequest.idContry,
      comprador_tipo_documento: userAvailableSpaceRequest.sellerDocumentType,
      comprador_documento: userAvailableSpaceRequest.sellerDocumentNumber,
      tipo_documento: userAvailableSpaceRequest.documentType,
      documento: userAvailableSpaceRequest.documentNumber,
      codigo_consulta: userAvailableSpaceRequest.queryCode,
    };
  }

  public static toReserveRequest(
    userAvailableSpaceRequest: UserAvailableSpaceRequest,
    reservationValue: number,
    username: string
  ): any {
    return {
      id_pais: userAvailableSpaceRequest.idContry,
      comprador_tipo_documento: userAvailableSpaceRequest.sellerDocumentType,
      comprador_documento: userAvailableSpaceRequest.sellerDocumentNumber,
      tipo_documento: userAvailableSpaceRequest.documentType,
      documento: userAvailableSpaceRequest.documentNumber,
      codigo_consulta: userAvailableSpaceRequest.queryCode,
      usuario: username,
      valor: reservationValue,
    };
  }
}
