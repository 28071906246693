import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-empty-state',
  template: `
  <div class="py-5 animated fadeIn">
    <div class="text-center py-5"> 
        <img class="img-fluid" width="90" height="77" src="assets/img/administration/icon-empty-search-table.svg" alt="icon empty search">
        <h5 class="text-primary mt-4">{{ title }}</h5>
        <p>{{ description }}.</p>
    </div>
  </div>
  `,
  styles: []
})
export class TableEmptyStateComponent {

  @Input() title: string = 'No hay registros para mostrar.';
  @Input() description: string = 'Por favor intente más tarde.';

  constructor() { }

}
