import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { HelperService } from '@services/helper.service';
import { Department } from '@models/department.model';
import { TransactionService } from '@services/transaction.service';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { ClientStudyResponse } from '@models/clientStudyResponse.model';
import { City } from '@models/city.model';
import { CONSTANTS } from '@config/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-study',
  templateUrl: './client-study.component.html',
  styleUrls: ['./client-study.component.scss'],
})
export class ClientStudyComponent implements OnInit {
  public departments: Array<Department>;

  // Radio
  private radioSelected = 'legal_person';
  public expeditionCities: Array<City> = [];
  public homeCities: Array<City> = [];
  public lastSaveDate: String = '';
  public resetError: boolean = false;
  public updateRefCities: Subject<boolean> = new Subject();
  private radio = [
    { name: 'Persona Jurídica', value: 'legal_person' },
    { name: 'Persona Natural', value: 'natural_person' },
  ];

  public initialDateFields = [
    'Menos de 1 año',
    '1 a 5 años',
    '5 a 10 años',
    'Más de 10 años',
  ];

  public addressFields = [
    'Calle',
    'Carrera',
    'Avenida',
    'Avenida Carrera',
    'Avenida Calle',
    'Circular',
    'Circunvalar',
    'Diagonal',
    'Manzana',
    'Transversal',
    'Vía',
    'Otra',
  ];

  public documentTypesOptions = [
    { LABEL: 'CEDULA DE CIUDADANIA', ID: 'C' },
    { LABEL: 'CEDULA DE EXTRANJERIA', ID: 'E' },
  ];

  public companyTypeFields = [
    { LABEL: 'Unipersonal', ID: 'UNIPERSONAL' },
    { LABEL: 'Sociedad', ID: 'SOCIEDAD' },
  ];

  public clientStudyFormTest: ClientStudyRequest = {
    ref1TypCompany: 'legal',
    ref2TypCompany: 'legal',
    ref1TypPerson: 'legal',
    ref2TypPerson: 'legal',
    addressType: 'Calle',
    addressFirst: 'cl 24 a norte',
    addressSecond: '18',
    addressThird: '20',
    addressOther: 'cl 24 a norte 18 20 conjunto alcalá casa 47',
    firstRefFirstName: 'Alberto',
    firstRefSecondName: '',
    firstRefFistLastName: 'Ramirez',
    firstRefSecondLastName: '',
    firstRefDepartment: '91',
    firstRefCity: '91263',
    firstRefPhone: '3113457684',
    firstRefEmail: 'Alberto@gmail.com',
    secondRefFirstName: 'Raul',
    secondRefSecondName: '',
    secondRefFistLastName: 'Hernandez',
    secondRefSecondLastName: '',
    secondRefDepartment: '91',
    secondRefCity: '91263',
    secondRefPhone: '3243456786',
    secondRefEmail: 'raul@gmail.com',
    clientType: 'legal',
    pointsOfSale: true,
    modeWP: false,
    modeEmail: false,
    modeSMS: false,
    modePhone: false,
    expeditionDate: moment('24/10/2019', 'DD/MM/YYYY').toDate(),
    sellerDocumentType: 'C',
    sellerDocumentNumber: '860222222',
    checkDigit: '1',
    expeditionDepartment: '91',
    expeditionCity: '85263',
    companyType: 'UNIPERSONAL',
    homeDepartment: '91',
    homeCity: '91263',
    cellular: '3002101010',
    phone: '3420011',
    email: 'prueba@prueba.com',
    birthDate: moment('28/10/2019', 'DD/MM/YYYY').toDate(),
    economicActivity: 'mercadeo',
    quotaValue: 30000000,
    companyName: 'prueba platafomra',
    legalRepresentativeDocumentType: 'C',
    legalRepresentativeDocumentNumber: '52100100',
    legalRepresentativeFirstName: 'Laura',
    legalRepresentativeSecondName: '',
    legalRepresentativeFistLastName: 'Perez',
    legalRepresentativeSecondLastName: '',
    contactPosition: 'mercadeo',
    contactFirstName: 'Claudia',
    contactSecondName: '',
    contactFirstLastName: 'Perez',
    contactSecondLastName: '',
    contactCellular: '3002202020',
    contactEmail: 'prueba@prueba.com',
    owners: [
      {
        documentType: 'C',
        documentNumber: '52101101',
        firstName: 'Patricia',
        secondName: '',
        firstLastName: 'Cespedes',
        secondLastName: 'Martinez',
      },
    ],
    contractNumber: '',
    initialDate: 'Menos de 1 año',
  };

  public clientStudyFormReset: ClientStudyRequest = {
    clientType: 'legal',
    ref1TypCompany: 'legal',
    ref2TypCompany: 'legal',
    ref1TypPerson: 'legal',
    ref2TypPerson: 'legal',
    pointsOfSale: false,
    modeWP: false,
    modeEmail: false,
    modeSMS: false,
    modePhone: false,
    expeditionDate: null,
    addressType: '',
    addressFirst: '',
    addressSecond: '',
    addressThird: '',
    addressOther: '',
    sellerDocumentType: '',
    sellerDocumentNumber: '',
    checkDigit: '',
    expeditionDepartment: '',
    expeditionCity: '',
    companyType: '',
    firstRefFirstName: '',
    firstRefSecondName: '',
    firstRefFistLastName: '',
    firstRefSecondLastName: '',
    firstRefDepartment: '',
    firstRefCity: '',
    firstRefPhone: '',
    firstRefEmail: '',
    secondRefFirstName: '',
    secondRefSecondName: '',
    secondRefFistLastName: '',
    secondRefSecondLastName: '',
    secondRefDepartment: '',
    secondRefCity: '',
    secondRefPhone: '',
    secondRefEmail: '',
    legalRepresentativeFirstName: '',
    legalRepresentativeSecondName: '',
    legalRepresentativeFistLastName: '',
    legalRepresentativeSecondLastName: '',
    homeDepartment: '',
    homeCity: '',
    cellular: '',
    phone: '',
    email: '',
    birthDate: null,
    economicActivity: '',
    quotaValue: 0,
    companyName: '',
    legalRepresentativeDocumentType: '',
    legalRepresentativeDocumentNumber: '',
    contactPosition: '',
    contactFirstName: '',
    contactSecondName: '',
    contactFirstLastName: '',
    contactSecondLastName: '',
    contactCellular: '',
    contactEmail: '',
    owners: [
      {
        documentType: '',
        documentNumber: '',
        firstName: '',
        secondName: '',
        firstLastName: '',
        secondLastName: '',
      },
    ],
    contractNumber: '',
    initialDate: null,
  };

  public clientStudyForm: ClientStudyRequest = Object.assign(
    {},
    this.clientStudyFormReset
  );

  constructor(
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private transactionService: TransactionService,
    public modal: ModalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.departments = this.helper.departments;
  }

  closeModal() {
    this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
  }

  goToStart() {
    this.router.navigateByUrl('/pages/transacciones');
  }

  closeErrorModal() {
    if (this.resetError) {
      this.resetError = false;
      this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
    }
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(
      (Item) => Item.value === this.radioSelected
    );
    return radioSel.value;
  }

  expeditionDepartmentChanged(expeditionDepartmentId) {
    this.spinner.show();
    this.helper.getCities(expeditionDepartmentId).subscribe(
      (cities: Array<City>) => {
        this.expeditionCities = cities;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  homeDepartmentChanged(expeditionDepartmentId) {
    this.spinner.show();
    this.helper.getCities(expeditionDepartmentId).subscribe(
      (cities: Array<City>) => {
        this.homeCities = cities;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  valueChanges(change) {
    this.saveForm();
  }

  validateChange(change) {
    this.validateForm();
  }

  validateForm() {
    if (
      this.clientStudyForm.sellerDocumentNumber &&
      (this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL ||
        this.clientStudyForm.sellerDocumentType)
    ) {
      this.spinner.show();
      this.transactionService.validateForm(this.clientStudyForm).subscribe(
        (error: boolean) => {
          if (this.clientStudyForm.homeDepartment) {
            this.homeDepartmentChanged(this.clientStudyForm.homeDepartment);
          }
          if (this.clientStudyForm.expeditionDepartment) {
            this.expeditionDepartmentChanged(
              this.clientStudyForm.expeditionDepartment
            );
          }
          if (
            this.clientStudyForm.firstRefDepartment ||
            this.clientStudyForm.secondRefDepartment
          ) {
            this.updateRefCities.next(true);
          }
          this.spinner.hide();
          if (error) {
            this.resetError = true;
            this.modal.show('error', {
              title: 'Error',
              message: 'Este comprador ya tiene un cupo asignado',
            });
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
    } else if (!this.clientStudyForm.sellerDocumentNumber) {
      const prevClientType: string = this.clientStudyForm.clientType;
      this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
      this.clientStudyForm.clientType = prevClientType;
    }
  }

  saveForm() {
    if (
      this.clientStudyForm.sellerDocumentNumber &&
      (this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL ||
        this.clientStudyForm.sellerDocumentType)
    ) {
      this.lastSaveDate = moment().format('YYYY-MM-DD HH:mm:ss');
      this.transactionService.saveForm(this.clientStudyForm).subscribe(
        (filingNumber: string) => {
          this.clientStudyForm.filingNumber = filingNumber;
        },
        (err) => {}
      );
    }
  }

  requestClientStudy($event) {
    this.spinner.show();
    this.transactionService.requestClientStudy(this.clientStudyForm).subscribe(
      (clientStudyResponse: ClientStudyResponse) => {
        const name =
          this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL
            ? this.clientStudyForm.companyName
            : `${this.clientStudyForm.legalRepresentativeFirstName} ${this.clientStudyForm.legalRepresentativeFistLastName}`;
        // const documentNumber =
        //   this.clientStudyForm.clientType !== 'legal'
        //     ? this.clientStudyForm.documentNumber
        //     : `${this.clientStudyForm.documentNumber}${this.clientStudyForm.checkDigit}`;
        this.spinner.hide();
        switch (clientStudyResponse.responseType) {
          case 0:
            // aprobada
            this.modal.show('requestApproved', {
              name: name,
              documentNumber: this.clientStudyForm.sellerDocumentNumber,
              billNumber: this.clientStudyForm.quotaValue,
              billValue: clientStudyResponse.approvedQuota,
              date: moment().format('DD/MM/YYYY'),
              code: clientStudyResponse.code,
            });
            break;
          case 1:
            // negado
            this.modal.show('requestDenied', {
              name: name,
              documentNumber: this.clientStudyForm.sellerDocumentNumber,
              billNumber: this.clientStudyForm.quotaValue,
              date: moment().format('DD/MM/YYYY'),
              code: clientStudyResponse.code,
            });
            break;
          case 2:
            // radicada
            this.modal.show('requestSettled', {
              name: name,
              documentNumber: this.clientStudyForm.sellerDocumentNumber,
              billNumber: this.clientStudyForm.quotaValue,
              date: moment().format('DD/MM/YYYY'),
              code: clientStudyResponse.code,
              hours: `${
                this.clientStudyForm.quotaValue
                  ? parseInt(this.clientStudyForm.quotaValue) <= 20000000
                    ? 2
                    : 24
                  : 2
              }`,
            });
            break;
          case 3:
            // error
            this.modal.show('error', {
              title: 'Error',
              message: clientStudyResponse.errorModalText,
            });
            break;

          default:
            break;
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
