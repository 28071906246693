import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private selectedError = new Subject<any>();
  public eventSelectedError$ = this.selectedError.asObservable();

  private selectedProfileImg = new Subject<string>();
  public eventSelectedProfileImg$ = this.selectedProfileImg.asObservable();

  private stateToggleMenuBS = new BehaviorSubject<boolean>(false);
  public stateMenu$ = this.stateToggleMenuBS.asObservable();

  private stateIsMobileBS$ = new BehaviorSubject<boolean>(false);
  public stateIsMobile$ = this.stateIsMobileBS$.asObservable();

  private stateMassiveOperation = new Subject<any>();
  public stateMassiveOperation$ = this.stateMassiveOperation.asObservable();

  private stateFileModal = new Subject<any>();
  public stateFileModal$ = this.stateFileModal.asObservable();

  constructor() { }

  sendText(selected: any) {
    this.selectedError.next(selected);
  }

  sendProfileImg(profileImg: any) {
    this.selectedProfileImg.next(profileImg);
  }

  menuCollapse(show: boolean) {
    this.stateToggleMenuBS.next(show);
  }

  isMobile(isMobile: boolean) {
    this.stateIsMobileBS$.next(isMobile);
  }

  returnToPrincipal(state: boolean) {
     this.stateMassiveOperation.next(state);
  }

  removeFiles() {
    this.stateFileModal.next();
  }
}
