import { BuyerSpace } from './buyerSpace.model';

export class UserAvailableSpaceResponse {
  activity: string;
  check: string;
  statusCode: string;
  reserveDays: string;
  invoice: string;
  location: string;
  maximumDays: string;
  product: string;
  reservation: string;
  buyerSpace: BuyerSpace;

  public static fromResponse(json: any): UserAvailableSpaceResponse {
    const userAvailableSpaceResponse: UserAvailableSpaceResponse = {
      activity: json['ACTIVIDAD'],
      check: json['CHEQUE'],
      statusCode: json['CODIGO_ESTADO'],
      reserveDays: json['DIAS_RESERVA'],
      invoice: json['FACTURA'],
      location: json['LOCALIZACION'],
      maximumDays: json['MAXIMO_DIAS'],
      product: json['PRODUCTO'],
      reservation: json['RESERVA'],
      buyerSpace:
        'CUPO_COMPRADOR' in json && json['CUPO_COMPRADOR'].length > 0
          ? BuyerSpace.fromResponse(json['CUPO_COMPRADOR'][0])
          : null,
    };
    return userAvailableSpaceResponse;
  }

}
