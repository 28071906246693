import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss'],
})
export class ModalSuccessComponent implements OnInit, OnDestroy {
  @Input() approvalCode: string = '123123';

  constructor(public modal: ModalService, private router: Router) {}

  ngOnInit() {}

  ngOnDestroy() {}

  goToStart() {
    this.router.navigateByUrl('/pages/transacciones');
  }
}
