import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-profile-update-success',
  templateUrl: './modal-profile-update-success.component.html',
  styleUrls: ['./modal-profile-update-success.component.scss']
})
export class ModalProfileUpdateSuccessComponent implements OnDestroy {

  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {  }

    ngOnDestroy() {
      this.modal.close();
    }

    accept() {
      this.modal.close();
      this.confirm.emit();
    }

}