import { SelectsService } from '@services/selects.service';
import * as moment from 'moment';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  DoCheck,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { DocumentType } from '@models/documentType.model';
import { HelperService } from '@services/helper.service';
import { Department } from '@models/department.model';
import { City } from '@models/city.model';
import { AddUserRequest } from '@models/addUserRequest.model';
import { UsersService } from '@services/users.service';
import { ModalService } from '@services/modal.service';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserRoles } from '@models/userRolesResponse.model';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit, DoCheck {
  public documentTypes: Array<DocumentType>;
  public departments: Array<Department>;
  public cities: Array<City>;
  public addUserRequest: AddUserRequest;
  public message: string = '';
  public showErrorMessage: boolean = false;
  public requireConfirmPass: boolean = false;
  public passEquals: boolean = false;
  public continue: boolean = true;
  public roles: Array<UserRoles>;

  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  submitUserForm: EventEmitter<AddUserRequest> = new EventEmitter<AddUserRequest>();
  @Input() userToEdit: AddUserRequest;
  @Input() action: string;
  @ViewChild('formAddUser') formAddUser: NgForm;

  dateForm: FormGroup;

  constructor(
    public helper: HelperService,
    public usersService: UsersService,
    public modal: ModalService,
    private selectsService: SelectsService,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public zone: NgZone
  ) {
    this.dateForm = this.fb.group({
      inputname: ['', [Validators.required, Validators.minLength(2)]],
    });
  }
  // this.dateForm.controls['inputName'].value

  ngOnInit() {
    this.selects();
    this.initForm();
    this.userRoles();
  }

  ngDoCheck() {
    this.cdRef.detectChanges();
  }

  dateTextChange($event) {
    this.addUserRequest.birthday = moment(
      $event.target.value,
      'DD/MM/YYYY'
    ).toDate();
  }

  initForm() {
    this.addUserRequest = new AddUserRequest();
    switch (this.action) {
      case 'add':
        this.formAdd();
        break;
      case 'edit':
        this.formEdit();
        break;
    }
  }

  userRoles() {
    this.selectsService.getUserRoles().subscribe(
      (roles: Array<UserRoles>) => {
        this.roles = roles;
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  passwordIsEqual() {
    const pass1 = this.addUserRequest.newPass || '';
    const pass2 = this.addUserRequest.confirmNewPass || '';

    if (pass1.length > 0) {
      this.requireConfirmPass = true;
      this.continue = false;
      this.formAddUser.form.controls['newPass'].markAsTouched();
      this.formAddUser.form.controls['confirmNewPass'].markAsTouched();
    } else {
      this.requireConfirmPass = false;
      this.continue = true;
      this.formAddUser.form.controls['newPass'].markAsUntouched();
      this.formAddUser.form.controls['confirmNewPass'].markAsUntouched();
      this.addUserRequest.confirmNewPass = '';
      return;
    }

    if (pass1 === pass2) {
      this.passEquals = true;
      this.continue = true;
    } else if (pass1.length >= 8 && pass2.length >= 8) {
      this.continue = false;
      const errMessage = `La nueva contraseña no coincide, 
                          por favor revise los campos en rojo y vuelva a intentar.`;
      this.message = errMessage;
      this.passEquals = false;
    }
  }

  formAdd() {
    this.addUserRequest = new AddUserRequest();
    this.validate();
  }

  formEdit() {
    this.addUserRequest = this.userToEdit;
    this.getCities(this.addUserRequest.department);
    this.validate();
  }

  validate() {
    const errMessage = `Los campos marcados con * son obligatorios,
                        diligencielos antes de enviar el formulario.`;
    this.message = errMessage;
  }

  selects() {
    this.documentTypes = this.helper.documentTypes;
    this.departments = this.helper.getDepartments();
  }

  getCities(department: string) {
    this.helper.getCities(department).subscribe(
      (cities: Array<City>) => {
        this.cities = cities;
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  backToUsersList() {
    this.back.emit();
  }

  submitForm() {
    switch (this.action) {
      case 'add':
        this.addUser();
        break;
      case 'edit':
        this.editUser();
        break;
    }
  }

  editUser() {
    this.submitUserForm.emit(this.addUserRequest);
  }

  addUser() {
    this.submitUserForm.emit(this.addUserRequest);
  }
}
