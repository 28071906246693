import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HelperService } from '@services/helper.service';

import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  // Dates
  public finalDate;
  public initDate;
  public min;
  public max;

  // Properties
  public selectedMoments = [];

  @Output() search: EventEmitter<object> = new EventEmitter();
  @Input() minInput: Date;
  @Input() maxInput: Date;
  @Input() initDateInput: Date;
  @Input() finalDateInput: Date;

  constructor(public helper: HelperService) {}

  ngOnInit() {
    this.min = this.minInput || new Date('2016-01-02');
    this.max = this.maxInput || new Date();
    this.finalDate = this.finalDateInput || new Date();
    this.initDate =
      this.initDateInput ||
      moment(this.finalDate).subtract(15, 'days').toDate();
    this.selectedMoments = [this.initDate, this.finalDate];
  }

  dateTextInitChange($event) {
    this.initDate =
      $event.target.value.length != 10
        ? moment().toDate()
        : moment($event.target.value, 'DD/MM/YYYY').toDate();
    this.selectedMoments = [this.initDate, this.finalDate];
  }

  dateTextFinalChange($event) {
    this.finalDate =
      $event.target.value.length != 10
        ? moment().toDate()
        : moment($event.target.value, 'DD/MM/YYYY').toDate();
    this.selectedMoments = [this.initDate, this.finalDate];
    if (this.finalDate < this.max && this.initDate > this.min) {
      this.search.emit(this.selectedMoments);
    }
  }

  selectRange($event) {
    let validEvent = true;
    if (typeof $event[0]._i === 'string' && $event[0]._i.length != 10) {
      validEvent = false;
    }
    if (typeof $event[1]._i === 'string' && $event[1]._i.length != 10) {
      validEvent = false;
    }
    if (validEvent) {
      this.search.emit($event);
    }
  }
}
