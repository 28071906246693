import { DocumentsService } from '@services/documents.service';
import { DocumentResponse } from '@models/documentResponse.model';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public isValid = false;

  // Radio
  public radioSel: any;
  public radioSelected = 'transaccion_individual';
  public documentsResponse: Array<DocumentResponse>;

  constructor(public spinner: NgxSpinnerService,
    public modal: ModalService,
    private documentService: DocumentsService) { }

  ngOnInit() {
    this.documents();
  }

  documents() {
    this.documentService.getDocuments().subscribe(
      (documents: Array<DocumentResponse>) => {
        this.documentsResponse = documents;
      }, (err: any) => {
        console.error(err);
        this.modal.show("error", {
          title: `No hay documentos para mostrar.`,
          message: "Por favor intente más tarde"
        });
      }
    )
  }

  viewDocument(url: string) {
    this.spinner.show();
    window.open(url, '_blank');
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }

}
