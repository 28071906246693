import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
@Component({
  selector: 'app-tab-massive-operation',
  templateUrl: './tab-massive-operation.component.html',
  styleUrls: ['./tab-massive-operation.component.scss'],
})
export class TabMassiveOperationComponent implements OnInit {
  @Output() operationType: EventEmitter<string> = new EventEmitter<string>();
  public permissions: Array<PermissionModel>;

  // Radio
  private radioSelected: string;
  public radio = [
    {
      name: 'Aviso de no pago masivo',
      value: 'noPay',
      img: 'assets/img/operations/icon-massive-nopay.svg',
    },
    {
      name: 'Exoneración masiva',
      value: 'exoneration',
      img: 'assets/img/operations/icon-massive-exoneration.svg',
    },
  ];

  constructor(private usersService: UsersService) {}

  filterPermission(idOption) {
    return this.permissions.filter(
      (permission) => permission.idOption === idOption
    );
  }

  ngOnInit() {
    this.permissions = this.usersService.permissions;
    const noPayPermissionStatus = this.filterPermission('12');
    const exonerationPermissionStatus = this.filterPermission('13');
    this.radio = [];
    if (
      noPayPermissionStatus.length > 0 &&
      noPayPermissionStatus[0].status === CONSTANTS.PERMISSIONS.ACTIVE
    ) {
      this.radio.push({
        name: 'Aviso de no pago masivo',
        value: 'noPay',
        img: 'assets/img/operations/icon-massive-nopay.svg',
      });
    }
    if (
      exonerationPermissionStatus.length > 0 &&
      exonerationPermissionStatus[0].status === CONSTANTS.PERMISSIONS.ACTIVE
    ) {
      this.radio.push({
        name: 'Exoneración masiva',
        value: 'exoneration',
        img: 'assets/img/operations/icon-massive-exoneration.svg',
      });
    }
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(
      (Item) => Item.value === this.radioSelected
    );
    if (radioSel && radioSel.value) {
      this.operationType.emit(radioSel.value);
      return radioSel.value;
    } else {
      return '';
    }
  }
}
