import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { CONSTANTS } from '@config/constants';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent implements OnInit {
  @Input() clientStudyForm: ClientStudyRequest;
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('formReference') formReference: NgForm;

  public constants = CONSTANTS;

  public addressFields = [
    'Calle',
    'Carrera',
    'Avenida',
    'Avenida Carrera',
    'Avenida Calle',
    'Circular',
    'Circunvalar',
    'Diagonal',
    'Manzana',
    'Transversal',
    'Vía',
    'Otra',
  ];

  constructor() {}

  ngOnInit() {}

  ngDoCheck() {
    this.formValidate();
  }

  formValidate() {
    const isFormValid = this.formReference.valid;
    this.formValid.emit(isFormValid);
  }
}
