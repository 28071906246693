import * as moment from 'moment';

export class TransactionDue {
  date: Date;
  dateString: string;
  value: number;
  title: string;
  check: boolean;

  public static toRequest(transactionDue: TransactionDue): any {
    return {
      fecha: moment(transactionDue.date).format('YYYYMMDD'),
      valor: transactionDue.value,
      titulo: transactionDue.title,
    };
  }
}
