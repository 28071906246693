import {
  TransactionsReportRequest,
  TransactionsReportResponse,
} from '@models/transactionsReport.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from '@models/user.model';
import {
  ReportRequest,
  ReportResponse,
  ReportAffiliateResponse,
  YearReportResponse,
} from '@models/reports.model';
import {
  SellerReportRequest,
  SellerReportResponse,
} from '@models/sellersReport.model';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getTransactionsReport(transactionsReportRequest: TransactionsReportRequest) {
    const { userInfo } = this.identity;
    transactionsReportRequest.documentNumber = userInfo.document;
    transactionsReportRequest.documentType = userInfo.documentType;
    transactionsReportRequest.buyerDocumentNumber =
      transactionsReportRequest.buyerDocumentNumber || '0';
    transactionsReportRequest.buyerDocumentType = userInfo.userDocumentType;
    transactionsReportRequest.records = 6;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: TransactionsReportRequest.toRequest(transactionsReportRequest),
      headers,
    };

    return this.http
      .get(`${this.urlApi}/pr_reporte_transacciones_v5?`, options)
      .pipe(
        map((resp: any) => {
          let reports: Array<TransactionsReportResponse> = [];
          reports =
            resp['REPORTE_TRANSACCIONES'] &&
            resp['REPORTE_TRANSACCIONES'].length > 0
              ? resp['REPORTE_TRANSACCIONES'].map(
                  (report: TransactionsReportResponse) =>
                    TransactionsReportResponse.fromResponse(report)
                )
              : [];
          return reports;
        })
      );
  }

  getSellersReport(sellerReportRequest: SellerReportRequest) {
    const { userInfo } = this.identity;
    sellerReportRequest.documentNumber = userInfo.document;
    sellerReportRequest.documentType = userInfo.documentType;
    sellerReportRequest.userDocumentNumber =
      sellerReportRequest.userDocumentNumber || '0';
    sellerReportRequest.userDocumentType = userInfo.userDocumentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: SellerReportRequest.toRequest(sellerReportRequest),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_vendedores?`, options).pipe(
      map((resp: any) => {
        const sellers: Array<SellerReportResponse> =
          resp['VENDEDORES'] && resp['VENDEDORES'].length > 0
            ? resp['VENDEDORES'].map((seller: SellerReportResponse) =>
                SellerReportResponse.fromResponse(seller)
              )
            : [];
        return sellers;
      })
    );
  }

  getReport() {
    const { document, documentType } = this.identity.userInfo;
    const reportRequest: ReportRequest = {
      documentNumber: document,
      documentType,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ReportRequest.toRequest(reportRequest),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_esferas?`, options).pipe(
      map((resp: any) => {
        const reportInfo: ReportResponse =
          resp['CONSOLIDADO_CUPOS'] && resp['CONSOLIDADO_CUPOS'].length > 0
            ? ReportResponse.fromResponse(resp['CONSOLIDADO_CUPOS'][0])
            : null;
        return reportInfo;
      })
    );
  }

  getAffiliatesReport() {
    const { document, documentType } = this.identity.userInfo;
    const reportRequest: ReportRequest = {
      documentNumber: document,
      documentType,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ReportRequest.toRequest(reportRequest),
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}/pr_reporte_transc_garant_por_tipo_comprador?`,
        options
      )
      .pipe(
        map((resp: any) => {
          const affiliatesReportInfo: ReportAffiliateResponse =
            resp['REPORTE_TRANSC_GARANT_POR_TIPO_COMPRADOR'] &&
            resp['REPORTE_TRANSC_GARANT_POR_TIPO_COMPRADOR'].length > 0
              ? ReportAffiliateResponse.fromResponse(
                  resp['REPORTE_TRANSC_GARANT_POR_TIPO_COMPRADOR']
                )
              : null;
          return affiliatesReportInfo;
        })
      );
  }

  getYearReport(prevYear: number, currentYear: number) {
    const { document, documentType } = this.identity.userInfo;
    const reportRequest: ReportRequest = {
      documentNumber: document,
      documentType,
    };
    const headers = new HttpHeaders().set('token', this.userToken);

    const options = {
      params: ReportRequest.toRequest(reportRequest),
      headers,
    };
    return this.http
      .get(`${this.urlApi}/pr_reporte_anual_garnt_transc_uso?`, options)
      .pipe(
        map((resp: any) => {
          const yearReportInfo: YearReportResponse =
            resp['REPORTE_GARNT_TRANSC_USO'] &&
            resp['REPORTE_GARNT_TRANSC_USO'].length > 0
              ? YearReportResponse.fromResponse(
                  resp['REPORTE_GARNT_TRANSC_USO'][0],
                  prevYear,
                  currentYear
                )
              : null;
          return yearReportInfo;
        })
      );
  }

  exportSellers(sellerReportRequest: SellerReportRequest, type: string) {
    const reportEndPoint =
      type === 'consolidated'
        ? 'pr_exportar_vendedores'
        : 'pr_exportar_vendedores_detalle';
    const { userInfo } = this.identity;
    sellerReportRequest.documentNumber = userInfo.document;
    sellerReportRequest.documentType = userInfo.documentType;
    sellerReportRequest.userDocumentNumber =
      sellerReportRequest.userDocumentNumber || '0';
    sellerReportRequest.userDocumentType = userInfo.userDocumentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: SellerReportRequest.toExportRequest(sellerReportRequest),
      headers,
    };

    return this.http.get(`${this.urlApi}/${reportEndPoint}`, {
      headers: options.headers,
      params: options.params,
      responseType: 'blob',
    });
  }

  exportTransactions(transactionsReportRequest: TransactionsReportRequest) {
    const { userInfo } = this.identity;
    transactionsReportRequest.documentNumber = userInfo.document;
    transactionsReportRequest.documentType = userInfo.documentType;
    transactionsReportRequest.buyerDocumentNumber =
      transactionsReportRequest.buyerDocumentNumber || '0';
    transactionsReportRequest.buyerDocumentType = userInfo.userDocumentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: TransactionsReportRequest.toExportRequest(
        transactionsReportRequest
      ),
      headers,
    };

    return this.http.get(
      `${this.urlApi}/pr_exportar_reporte_transacciones_v4`,
      {
        headers: options.headers,
        params: options.params,
        responseType: 'blob',
      }
    );
  }

  exportReport() {
    const { document, documentType } = this.identity.userInfo;
    const reportRequest: ReportRequest = {
      documentNumber: document,
      documentType,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ReportRequest.toRequest(reportRequest),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_exportar_cupo_v2`, {
      headers: options.headers,
      params: options.params,
      responseType: 'blob',
    });
  }
}
