import * as moment from 'moment';
export class TransactionsReportRequest {
  documentType?: string;
  documentNumber?: string;
  buyerDocumentType?: string;
  buyerDocumentNumber?: string;
  startDate: Date;
  endDate: Date;
  page: number;
  records?: number;

  public static toRequest(
    transactionsReportRequest: TransactionsReportRequest
  ): any {
    return {
      id_pais: 1,
      // usuario: transactionsReportRequest.user,
      tipo_documento: transactionsReportRequest.documentType,
      documento: transactionsReportRequest.documentNumber,
      // comprador_tipo_documento: transactionsReportRequest.buyerDocumentType,
      busqueda: transactionsReportRequest.buyerDocumentNumber,
      fecha_inicial: moment(transactionsReportRequest.startDate).format(
        'DD/MM/YYYY'
      ),
      fecha_final: moment(transactionsReportRequest.endDate).format(
        'DD/MM/YYYY'
      ),
      pagina: transactionsReportRequest.page,
      registros: transactionsReportRequest.records,
      titulo_valor: 0,
    };
  }

  public static toExportRequest(
    transactionsReportRequest: TransactionsReportRequest
  ): any {
    return {
      id_pais: 1,
      // usuario: transactionsReportRequest.user,
      tipo_documento: transactionsReportRequest.documentType,
      documento: transactionsReportRequest.documentNumber,
      comprador_tipo_documento: transactionsReportRequest.buyerDocumentType,
      comprador_documento: transactionsReportRequest.buyerDocumentNumber,
      fecha_inicial: moment(transactionsReportRequest.startDate).format(
        'DD/MM/YYYY'
      ),
      fecha_final: moment(transactionsReportRequest.endDate).format(
        'DD/MM/YYYY'
      ),
      titulo_valor: 0,
    };
  }
}

export class TransactionsReportResponse {
  document: string;
  documentType: string;
  name: string;
  valueTitle: number;
  value: number;
  expiration: number;
  code: number;
  state: string;
  user: string;
  numberRecords: number;

  public static fromResponse(json: any): TransactionsReportResponse {
    const transactionsReportResponse: TransactionsReportResponse = {
      document: json['DOCUMENTO'],
      documentType: json['TIPO_DOCUMENTO'],
      name: json['CLIENTE'],
      valueTitle: json['TITULO_VALOR'],
      value: json['VALOR_GARANTIZADO'],
      expiration: json['VENCIMIENTO'],
      code: json['AUTORIZACION'],
      state: json['ESTADO'],
      user: json['USUARIO'],
      numberRecords: json['N_REGISTROS'],
    };
    return transactionsReportResponse;
  }
}
