import { Component, Input, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-transaction',
  templateUrl: './modal-transaction.component.html',
  styleUrls: ['./modal-transaction.component.scss']
})
export class ModalTransactionComponent implements OnDestroy {
    // poperties
    @Input() textButton: string;
    @Input() colorButton = 'btn-primary';

  constructor(public modal: ModalService) { }

  ngOnDestroy() {
    this.modal.close();
  }


}
