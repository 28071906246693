export class UserPhotoRequest {
    idContry?: string = '1';
    documentType?: string;
    documentNumber?: string;
    userDocumentType: string;
    userDocumentNumber: string;
    type: string = 'perfil';
    photo: any;
  
    public static toRequest(userPhotoRequest: UserPhotoRequest): any {
      return {
        id_pais: userPhotoRequest.idContry,
        tipo_documento: userPhotoRequest.documentType,
        documento: userPhotoRequest.documentNumber,
        tipo: userPhotoRequest.type,
        usuario_tipo_documento: userPhotoRequest.userDocumentType,
        usuario_documento: userPhotoRequest.userDocumentNumber,
      };
    }
}

export class UserPhotoResponse {
  url: string;

  public static toRequest(userPhotoResponse: UserPhotoResponse): any {
    return {
      url: userPhotoResponse.url
    };
  }
}