import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { MassiveRequest } from '@models/massiveRequest.model';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  public activeEmptyState = true;
  public massiveRequet: MassiveRequest;
  public permissions: Array<PermissionModel>;
  showIndividual = false;
  showMassive = false;
  showReserves = false;

  navItemTabsInfo = [
    {
      href: '#individual',
      description: 'Transacción individual',
      icon: 'icon-tab-individual.svg',
      idOption: '2',
    },
    {
      href: '#massive',
      description: 'Transacción masiva',
      icon: 'icon-tab-massive.svg',
      idOption: '6',
    },
    {
      href: '#reserves',
      description: 'Listado de reservas',
      icon: 'icon-tab-reserves.svg',
      idOption: '7',
    },
  ];
  public navItemTabs = null;

  constructor(
    private router: Router,
    public modal: ModalService,
    public helper: HelperService,
    private usersService: UsersService
  ) {}

  showTab(idOption) {
    let showItem = false;
    switch (idOption) {
      case 2:
        showItem = this.showIndividual;
        break;
      case 6:
        showItem = !this.showIndividual && this.showMassive;
        break;
      default:
        showItem =
          !this.showIndividual && !this.showMassive && this.showReserves;
        break;
    }

    return showItem;
  }

  ngOnInit() {
    this.permissions = this.usersService.permissions;
    this.navItemTabs = this.navItemTabsInfo
      .filter((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption === navItemTab.idOption
        );
        const showItem =
          permissionStatus.length > 0 &&
          permissionStatus[0].status === CONSTANTS.PERMISSIONS.ACTIVE;

        switch (navItemTab.idOption) {
          case '2':
            this.showIndividual = showItem;
            break;
          case '6':
            this.showMassive = showItem;
            break;
          default:
            this.showReserves = showItem;
            break;
        }
        return showItem;
      })
      .map((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption === navItemTab.idOption
        );
        if (permissionStatus.length > 0) {
          navItemTab['status'] = permissionStatus[0].status;
        }
        return navItemTab;
      });
  }

  goToMassive(massiveRequets: MassiveRequest) {
    this.massiveRequet = massiveRequets;
    this.activeEmptyState = false;
  }

  back() {
    this.activeEmptyState = true;
  }
}
