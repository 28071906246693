import { Component, OnInit } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReserveService } from '@services/reserve.service';
import { Reserve } from '@models/reserve.model';

@Component({
  selector: 'app-tab-reserves',
  templateUrl: './tab-reserves.component.html',
  styleUrls: ['./tab-reserves.component.scss'],
})
export class TabReservesComponent implements OnInit {
  public reserveList: Array<Reserve> = [];
  public selectedReserve: Reserve;

  // Radio
  public radioSel: any;
  public radioSelected: string;
  public sellerDocumentNumber: string = '0';
  public totalPages: number = 1;
  public pageLimit: number = 10;
  public page: number = 1;

  constructor(
    public modal: ModalService,
    public router: Router,
    public reserveService: ReserveService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.loadReserves();
  }

  loadReserves() {
    this.spinner.show();
    this.reserveService
      .getReserves(
        this.page.toString(),
        this.pageLimit.toString(),
        this.sellerDocumentNumber
      )
      .subscribe(
        (reserveList: Array<Reserve>) => {
          this.reserveList = reserveList;
          this.totalPages =
            reserveList.length > 0
              ? Math.ceil(
                  parseInt(reserveList[0].numberOfRecords) / this.pageLimit
                )
              : 1;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  pageSelected(page: any) {
    this.page = page.currentPage;
    this.loadReserves();
  }

  onSearch(searchText: string) {
    this.page = 1;
    this.sellerDocumentNumber = searchText ? searchText : '0';
    this.selectedReserve = null;
    this.loadReserves();
  }
  checkSelectedReserve(reserve: Reserve) {
    this.selectedReserve = reserve;
  }
  goTransaction() {
    if (this.selectedReserve) {
      this.router.navigateByUrl(
        `/pages/transacciones/transaccion-individual/${this.selectedReserve.documentType}/${this.selectedReserve.document}/${this.selectedReserve.queryCode}/${this.selectedReserve.reservationCode}`
      );
    }
  }
}
