import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-payment-confirm',
  templateUrl: './modal-payment-confirm.component.html',
  styleUrls: ['./modal-payment-confirm.component.scss']
})
export class ModalPaymentConfirmComponent implements OnDestroy {

  constructor(public modal: ModalService) { }

  ngOnDestroy() {
    this.modal.close();
  }

}
