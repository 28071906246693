export class PaymentAgreementsListRequest {
  idContry: string;
  documentType: string;
  documentNumber: string;
  page: string;
  records: string;
  search: string;

  public static toRequest(
    paymentAgreementsListRequest: PaymentAgreementsListRequest
  ): any {
    return {
      id_pais: paymentAgreementsListRequest.idContry,
      tipo_documento: paymentAgreementsListRequest.documentType,
      documento: paymentAgreementsListRequest.documentNumber,
      busqueda: paymentAgreementsListRequest.search,
      pagina: paymentAgreementsListRequest.page,
      registros: paymentAgreementsListRequest.records,
    };
  }
}
